<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
<!--    <nav2></nav2>-->
    <headerVue @toggleBar="toggleBar"></headerVue>
<!--    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>-->
    <div class="bg-title" :style="{'margin-top': barVisible ? '60px' : 0}">
      <div class="text-title">企业新闻</div>
      <div class="text-content">更多最新精彩资讯，感受企业魅力</div>
    </div>
    <!-- 面包屑 -->
    <div class="Crumbs mobile-crumbs">
      <div>当前位置：首页><span>招聘信息</span></div>
    </div>
    <!-- 招聘信息 -->
    <div class="Recruitment">
      <div class="Recruitment_cont mobile-cont">
        <div
          class="Recruitment_cont_item1 mobile-cont-item"
          v-for="(item, index) in arrList"
          :key="index"
          @click="onurl(index)"
        >
          <div class="Recruitment_cont_item1_p">
            <p>{{ item.p1 }}</p>
            <p>{{ item.p2 }}</p>
          </div>
          <div class="Recruitment_cont_item1_div1">
            <div>{{ item.div1 }}</div>
            <div>{{ item.div2 }}</div>
            <div>{{ item.div3 }}</div>
          </div>
          <div class="Recruitment_cont_item1_div1 div2">
            <div>
              {{ item.div4 }}<span class="div1_span">{{ item.span1 }}</span>
            </div>
            <div>{{ item.div5 }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 翻页 -->
<!--    <Pages></Pages>-->

    <!-- 申请职位 -->
    <applyFor></applyFor>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import footer2 from "../components/footer.vue";
// import banner2 from "../components/banner.vue";
// import Pages from "../components/recruitInfoPages.vue";
// import nav2 from "../components/nav.vue";
import applyFor from "../components/applyFor.vue";
import headerVue from "../components/header.vue";

export default {
  name: "zhaopinxinxi",
  components: {
    footer2,
    // nav2,
    // banner2,
    // Pages,
    applyFor,
    headerVue,
  },
  data() {
    return {
      h1: "招聘信息",
      Img1: require("../assets/img/keji/矿山banner1.png"),
      barVisible: false,

      p1: "更多最新精彩资讯，感受企业魅力",
      arrList: [
        {
          p1: "高级程序员",
          p2: "面议",
          div1: "学历要求：大专以上",
          div2: "工作经验：5年以上",
          div3: "招聘人数：1人",
          div4: "工作地点：",
          span1: "吉林长春",
          div5: "联系方式：136 1116 4307",
        },
        {
          p1: "商务代表",
          p2: "面议",
          div1: "学历要求：大专以上",
          div2: "工作经验：2年以上",
          div3: "招聘人数：1人",
          div4: "工作地点：",
          span1: "吉林长春",
          div5: "联系方式：136 1116 4307",
        }
      ],
    };
  },
  methods: {
    onurl(index) {
      console.log("index", index)
      // if (index < 2) {
      //   this.$router.push("/zhaopinxiangqing0_" + index);
      // } else {
      //   alert("页面未创建无法跳转");
      // }
    },
    toggleBar(barVisible){
      console.log("qiehuan", barVisible)
      this.barVisible = barVisible
    }
  },
};
</script>
<style scoped>
@import "../assets/css/recruit-info.css";

.bg-title{
  background: url("../assets/img/keji/矿山banner1.png");
  width: 100vw;
  height: 300px;
  text-align: center;
  /*line-height: 300px;*/
  color: #FFFFFF;
}

.text-title{
  padding-top: 105px;
  font-size: 40px;
}

.text-content{
  margin-top: 20px;
}

.Recruitment_cont{
  height: auto;
  margin-bottom: 61px;
}



@media only screen and (max-width: 800px){
  .mobile-crumbs{
    width: 100vw;
    padding-left: 20px;
  }

  .mobile-cont{
    width: 100vw;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-cont-item{
    margin: 10px auto 20px;
  }

  .Recruitment_cont_item1{
    width: 75%;
    height: 143px;
  }
}
</style>


