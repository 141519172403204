<template>
	<!-- star -->
	<div class="homepage_wrap" id="outerDom" ref="outerDom" @scroll="getScrollTop">

    <div v-show="scrollVisible" @click="scrollToAnchor('outerDom')" class="back-top">
      <img alt="回到顶部" src="../assets/img/back-to-top.png"/>
    </div>

		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- banner -->
			<div class="banner_nav_bg">
				<img src="../assets/img/bg1.png" alt="" />
			</div>
			<!-- 导航栏 -->
			<div class="navWrap">
				<div class="navWrap_center">

          <div class="navWrap_center_img">
            <img src="../assets/img/logo.png" alt="" />
          </div>

          <div style="display: flex; margin-right: 50px">
            <div class="dropdown">
              <button class="dropbtn">首页</button>
            </div>
            <div class="dropdown">
              <button class="dropbtn">汽车电子服务</button>
              <div class="dropdown-content">
                <div class="dropdown-item" @click="scrollToAnchor('car-develop')">自研产品</div>
                <div class="dropdown-item" @click="scrollToAnchor('car1')">汽车电子软件开发工程服务和咨询服务</div>
                <div class="dropdown-item" @click="scrollToAnchor('car2')">汽车电子测试领域人力外包服务</div>
                <div class="dropdown-item" @click="scrollToAnchor('car3')">零部件级别信息安全测试</div>
                <div class="dropdown-item">汽车电子领域软件技术培训</div>
              </div>
            </div>

            <div class="dropdown-software">
              <button class="dropbtn">应用软件服务</button>
              <div class="dropdown-content">
                <div class="dropdown-item" @click="toDoc">自研产品</div>
              </div>
            </div>

            <div class="dropdown-plugin">
              <button class="dropbtn" @click="toConvert">小插件</button>
<!--              <div class="dropdown-content">-->
<!--                <div class="dropdown-item">待定</div>-->
<!--                <div class="dropdown-item">待定</div>-->
<!--                <div class="dropdown-item">待定</div>-->
<!--              </div>-->
            </div>

            <div class="dropdown">
              <button class="dropbtn" @click="toContact">联系我们</button>
            </div>
          </div>
				</div>
			</div>

      <div class="navWrap-mobile" :style="{height: navItemVisible? '100vh': '100px'}">
        <div class="navWrap-bar">
          <div class="nav-img-side" @click="toggleNavSlide"><img :src="navItemVisible ? closePng : listPng"></div>
          <div class="nav-img-center"><img src="../assets/img/logo.png"></div>
          <div class="nav-img-side"></div>
        </div>
        <div class="nav-item-content" :class="{'nav-position': navItemVisible}" v-show="navItemVisible">
          <ul>
            <li v-for="(item) in navList" :key="item.title" class="nav-item-text">
              <div class="nav-link" @click="toggleNav(item)">
                <div class="nav-title">{{item.title}}</div>
                <div class="nav-img" v-if="item.children"><img :src="item.isOpen ? arrowUp : arrowDown"/></div>
              </div>
              <ul class="nav-dropdown" ref="{{item.ref}}" v-show="item.isOpen">
                <li v-for="child in item.children" :key="child.title" class="nav-dropdown-item" @click="scrollToAnchor(child.anchor, child.url)">
                  {{child.title}}
                </li>
              </ul>
            </li>
          </ul>

        </div>

      </div>
			<!-- title -->
			<div class="banner_title_wrap">
				<div class="banner_title_center">
					<p>{{ title }}</p>
					<div>引领<span>·</span>智慧<span>·</span>勇敢</div>
				</div>
			</div>
		</div>
		<!-- 最新资讯 -->
		<div class="news">
			<div class="news_cont">
				<!-- left -->
				<div class="news_cont_left">
					<h1>最新资讯</h1>
					<div class="news_bg">
						<div class="news_cont_left_img">
							<img src="../assets/img/news_img1.png" alt="" />
						</div>
						<div class="news_cont_left_text">
							<h1>要让学生知道，努力学习是一种责任！</h1>
							<p>2021-05-30</p>
							<p>
								中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任
							</p>
							<p>中国预防青少年犯罪研究会的副会长...</p>
						</div>
					</div>
				</div>
				<!-- right -->
				<div class="news_cont_right">
					<div class="news_cont_right_ul">
						<ul>
							<li>集团新闻</li>

							<span>|</span>
							<li>媒体报道</li>
							<span>|</span>

							<li>行业资讯</li>
						</ul>
					</div>
					<div class="news_cont_right_text">
						<div
							class="news_cont_right_text_item news_cont_right_text_hr"
							v-for="(item, index) in newList"
							:key="index"
						>
							<h1>{{ item.title }}</h1>
							<p>{{ item.time }}</p>
							<p>
								{{ item.text }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

    <!-- 汽车电子服务  自研产品 -->
    <div id="car-develop" class="module-area">
      <div class="module-body">
        <div class="module-flex">
          <h1>汽车电子服务</h1><h3 style="line-height: 50px;">自研产品</h3>
        </div>

        <div class="module-content">
          <!-- left -->
          <div class="module-image-area padding-text padding-top-20" style="text-align: center">
            <h2>基于RH850控制器的平台</h2>

            <div class="margin-top-70">
              <div class="module-text-list">
                 <h6 style="width: 100%; margin: 0">平台采用AutoSAR架构，可以支持AutoSAR version 4.2.2</h6>
              </div>
            </div>
          </div>
          <!-- right -->
          <div class="module-image-area">
            <div class="module-image-title"> </div>
            <div class="module-image-subTitle"> </div>
            <div class="module-image">
              <img src="../assets/img/car/car-develop.jpg"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 汽车电子服务  汽车电子软件开发工程服务和资询服务 -->
    <div id="car1" class="module-area">
      <div class="module-body">
        <div class="module-flex">
          <h1>汽车电子服务</h1><h3 style="line-height: 50px;">汽车电子软件开发工程服务和资询服务</h3>
        </div>

        <div class="module-content">
          <!-- left -->
          <div class="module-image-area padding-text padding-top-20" style="text-align: center">
            <h2>AutoSAR CP基础软件配置、集成、测试验证解决方案</h2>

            <div class="margin-top-70" @click="toNews(0)">
              <div class="module-text-list padding-15">
                <p class="p-content">&nbsp;&nbsp;&nbsp;&nbsp;随着汽车电子系统的复杂性和集成度日益提高，AutoSAR CP（Classic Platform）作为基础软件架构，已经成为汽车电子系统开发的重要基石。
                  为了满足汽车行业对于高效、可靠且符合标准的软件解决方案的需求，我们推出了AutoSAR CP基础软件配置、集成、测试验证的全方位服务。</p>
              </div>
            </div>
            <div class="p-detail padding-15" @click="toNews(0)">详情...</div>
          </div>
          <!-- right -->
          <div class="module-image-area">
            <div class="module-image-title">Auto SAR CP</div>
            <div class="module-image-subTitle">基础软件配置、集成和测试验证</div>
            <div class="module-image">
              <img src="../assets/img/car/car1.png"/>
            </div>
          </div>
        </div>

<!--        <div class="module-content">-->
<!--          &lt;!&ndash; left &ndash;&gt;-->
<!--          <div class="module-image-area">-->
<!--            <div class="module-image-title">Auto SAR CP</div>-->
<!--            <div class="module-image-subTitle">基础软件配置、集成和测试验证</div>-->
<!--            <div class="module-image">-->
<!--              <img src="../assets/img/car/car1.png"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash; right &ndash;&gt;-->
<!--          <div class="module-image-area">-->
<!--            <div class="module-image-title">嵌入式Tessy测试</div>-->
<!--            <div class="module-image-subTitle">基础软件配置、集成和测试验证</div>-->
<!--            <div class="module-image">-->
<!--              <img src="../assets/img/car/car2.png"/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="module-area">
      <div class="module-body">
        <div class="module-content">
          <!-- left -->
          <div class="module-image-area padding-text padding-top-20" style="text-align: center">
            <h2>嵌入式Tessy测试服务</h2>

            <div class="margin-top-70" @click="toNews(1)">
              <div class="module-text-list padding-15">
                <p class="p-content">&nbsp;&nbsp;&nbsp;&nbsp;我们提供专业的嵌入式Tessy测试服务，旨在确保您的嵌入式软件系统达到最高的质量标准。Tessy是一种功能强大的自动化测试工具，专为嵌入式软件开发而设计，能够有效地验证和验证嵌入式系统的功能和性能。</p>
              </div>
            </div>
            <div class="p-detail padding-15" @click="toNews(1)">详情...</div>
          </div>
          <!-- right -->
          <div class="module-image-area">
            <div class="module-image-title">嵌入式Tessy测试</div>
            <div class="module-image-subTitle">基础软件配置、集成和测试验证</div>
            <div class="module-image">
              <img src="../assets/img/car/car2.png"/>
            </div>
          </div>
        </div>

        <!--        <div class="module-content">-->
        <!--          &lt;!&ndash; left &ndash;&gt;-->
        <!--          <div class="module-image-area">-->
        <!--            <div class="module-image-title">Auto SAR CP</div>-->
        <!--            <div class="module-image-subTitle">基础软件配置、集成和测试验证</div>-->
        <!--            <div class="module-image">-->
        <!--              <img src="../assets/img/car/car1.png"/>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; right &ndash;&gt;-->
        <!--          <div class="module-image-area">-->
        <!--            <div class="module-image-title">嵌入式Tessy测试</div>-->
        <!--            <div class="module-image-subTitle">基础软件配置、集成和测试验证</div>-->
        <!--            <div class="module-image">-->
        <!--              <img src="../assets/img/car/car2.png"/>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <div id="car2" class="module-area">
      <div class="module-body">
        <div class="module-flex">
          <h1>汽车电子服务</h1><h3 style="line-height: 50px;">车载网络测试外包服务</h3>
        </div>

        <div class="module-content">
          <!-- left -->
          <div class="module-image-area padding-text padding-top-20" style="text-align: center">
            <h2>提供服务：车载网络测试外包服务</h2>

            <div class="margin-top-70" @click="toNews(2)">
              <div class="module-text-list padding-15">
                <p class="p-content">&nbsp;&nbsp;&nbsp;&nbsp;我们提供全面的车载网络测试外包服务，通信网络包括车载以太网、CAN、LIN，涵盖刷写、诊断、OTA、网关、HIL、Tessy、BCM、TBOX、SOME/IP多个关键领域。作为汽车行业的测试专家，我们致力于帮助客户确保其车载系统的稳定性、安全性和性能优化，以应对快速发展和日益复杂的车载技术挑战。</p>
              </div>
            </div>
            <div class="p-detail padding-15" @click="toNews(2)">详情...</div>
          </div>
          <!-- right -->
          <div class="module-image-area">
            <div class="module-image-title"> </div>
            <div class="module-image-subTitle"> </div>
            <div class="module-image">
              <img src="../assets/img/car/car3.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 汽车电子服务  车载网络测试外包服务 -->
<!--    <div id="car23" class="module-area">-->
<!--      <div class="module-body">-->
<!--        <div class="module-flex">-->
<!--          <h1>汽车电子服务</h1><h3 style="line-height: 50px;">车载网络测试外包服务</h3>-->
<!--        </div>-->

<!--        <div class="module-content">-->
<!--          &lt;!&ndash; left &ndash;&gt;-->
<!--          <div class="module-image-area padding-text">-->
<!--            <h2>车载以太网、CAN、LIN、通讯测试外包服务；</h2>-->
<!--            <h2 style="margin-top: 10px">刷写、诊断、OTA、网关、等功能模块的自动测试外包服务。</h2>-->

<!--            <div style="margin-top: 70px">-->
<!--              <div class="module-text-list module-flex">-->
<!--                <h3>车载网络测试</h3> <h6>红旗C095、解放J7、红旗C100、柴油国六自动化平台。</h6>-->
<!--              </div>-->
<!--              <div style="margin-top: 20px" class="module-text-list module-flex">-->
<!--                <h3>HIL功能测试</h3> <h6>红旗C095、解放J7、红旗C100、柴油国六自动化平台。</h6>-->
<!--              </div>-->
<!--              <div style="margin-top: 20px" class="module-text-list module-flex">-->
<!--                <h3>Tessy测试</h3> <h6>恒润EAS470。</h6>-->
<!--              </div>-->
<!--              <div style="margin-top: 20px" class="module-text-list module-flex">-->
<!--                <h3>BCM测试</h3> <h6>红旗C100、红旗C001、红旗C206、红旗C229、红旗C255、红旗C095、红旗C206 phev、红旗C100 phev、-->
<!--                红旗E115 国内版、红旗E111海外版、红旗N897、中华汽车、红旗V506。</h6>-->
<!--              </div>-->
<!--              <div style="margin-top: 20px" class="module-text-list module-flex">-->
<!--                <h3>T-BOX测试</h3> <h6>红旗C255、奔腾D501、奔腾D501 phev、奔腾NAT。-->
<!--              </h6>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash; right &ndash;&gt;-->
<!--          <div class="module-image-area">-->
<!--            <div class="module-image-title"> </div>-->
<!--            <div class="module-image-subTitle"> </div>-->
<!--            <div class="module-image">-->
<!--              <img src="../assets/img/car/car3.png"/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 汽车电子服务  零部件级别信息安全测试（建设中） -->
    <div id="car3" class="module-area">
      <div class="module-body">
        <div class="module-flex">
          <h1>汽车电子服务</h1><h3 style="line-height: 50px;">零部件级别信息安全测试（建设中）</h3>
        </div>

        <div class="module-content">
          <!-- left -->
          <div class="module-image-area padding-60">
            <h2 class="margin-top-30-percent">控制器级别的渗透测试；</h2>
            <h2 style="margin-top: 10px">控制器级别的合规测试。
            </h2>
          </div>
          <!-- right -->
          <div class="module-image-area">
            <div class="module-image-title"> </div>
            <div class="module-image-subTitle"> </div>
            <div class="module-image">
              <img src="../assets/img/car/car4.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>

		<!-- 集团产业 -->
		<div class="GroupIndustry">
			<div class="GroupIndustry_cont">
				<!-- 左 -->
				<div class="GroupIndustry_cont_left">
					<!-- title -->
					<div class="GroupIndustry_cont_left_title">
						<h1>集团产业</h1>
					</div>
					<!-- img -->
					<div class="GroupIndustry_cont_left_img_f">
						<div
							v-for="(item, index) in jituanList"
							:key="index"
							class="GroupIndustry_cont_left_img"
						>
							<img :src="item.jituanimg" />
							<img
								:src="item.jituanimgyinying"
								class="GroupIndustry_cont_left_img2"
							/>
							<div class="GroupIndustry_cont_left_text">
								<span>{{ item.jituanspan }}</span>
								<p>{{ item.jituanp }}</p>
							</div>
						</div>
					</div>
					<!-- right -->
				</div>
				<!-- right -->
				<div class="GroupIndustry_cont_right">
					<!-- title -->
					<p class="GroupIndustry_cont_right_title">更多></p>
					<div class="GroupIndustry_cont_right_img">
						<img src="../assets/img/jituanyou.png" alt="" />
						<img
							class="GroupIndustry_cont_right_img2"
							src="../assets/img/jituanyinying.png"
							alt=""
						/>
					</div>
					<div class="GroupIndustry_cont_right_text">
						<p>执教寒夜</p>
						<span>校企共建专业 | 校企共建二级学院 | 校企共建产业学院</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 关于企业 -->
		<div class="AboutEnterprises">
			<div class="AboutEnterprises_cont">
				<!-- top -->
				<div class="AboutEnterprises_cont_top">
					<!-- top_left -->
					<div class="AboutEnterprises_cont_top_left">
						<h1>关于企业</h1>
						<p>
              吉林睿库信息科技有限公司是一家集金融、科技、电商、教育等为一体的集团化公司。在国家“科教兴国，金融助力”的大政策背景下，通过创新金融产品助力产业升级及国民经济转型，促进社会可持续发展；以科技创新推进智慧建设，以电商创新平台带动区域经济特色发展，以产业带动专业建设，深化产业与教育深度融合，以教育助力国家现代化建设。集团公司秉承脚踏实地、志存高远的企业精神，落实内涵建设，坚持守正创新，通过校企合作共建专业、共建二级学院、共建附属幼教机构等模式，致力于让人才更有价值，让科技、教育惠及大众，走出了一条特色化的发展道路。
						</p>

            <div class="AboutEnterprises_cont_top_left_btn">
              <span>了解更多</span>
              <img src="../assets/img/guanyujiantou.png" alt="" />
            </div>

					</div>
					<!-- top_right -->
					<div class="AboutEnterprises_cont_top_right">
						<img src="../assets/img/guanyuyou.png" alt="" />
						<div></div>
					</div>
				</div>
				<!-- bottom -->
				<div class="AboutEnterprises_cont_bottom">
					<div class="AboutEnterprises_cont_bottom_item">
						<h1>30</h1>

						<div class="AboutEnterprises_cont_bottom_item2">
							<span>+</span>
							<p>项目</p>
						</div>
					</div>
					<div class="AboutEnterprises_cont_bottom_item">
						<h1>60</h1>
						<div class="AboutEnterprises_cont_bottom_item2">
							<span>+</span>
							<p>客户</p>
						</div>
					</div>
					<div class="AboutEnterprises_cont_bottom_item">
						<h1>20</h1>
						<div class="AboutEnterprises_cont_bottom_item2">
							<span>+</span>
							<p>员工</p>
						</div>
					</div>
					<div
						class="AboutEnterprises_cont_bottom_item"
						style="border: 0; margin-right: -70px"
					>
						<h1>1w</h1>
						<div class="AboutEnterprises_cont_bottom_item2">
							<span>+</span>
							<p>用户</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 合作伙伴 -->
		<div class="CooperativePartner">
			<div class="CooperativePartner_cont">
				<h1 class="CooperativePartner_cont_title">合作伙伴</h1>
			</div>
			<div class="CooperativePartner_cont_center">
				<div class="CooperativePartner_cont_center_item">
					<img src="../assets/img/合作1.png" alt="" />
					<span>合作单位</span>
				</div>
				<div class="CooperativePartner_cont_center_item">
					<img src="../assets/img/合作2.png" alt="" />
					<span>合作院校</span>
				</div>
			</div>
			<div class="CooperativePartner_cont_bottom">
				<div
					v-for="(item, index) in logoList"
					:key="index"
					class="CooperativePartner_cont_bottom_logo"
				>
					<img :src="item.logo" alt="" />
				</div>
			</div>
		</div>

		<!-- footer -->
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import closePng from '../assets/img/close.png';
import listPng from '../assets/img/list.png';
import arrowUp from '../assets/img/arrow-up.png'
import arrowDown from '../assets/img/arrow-down.png'

export default {
	name: "index",
	components: {
		footer2,
	},
	data() {
		return {
			navList: [
				{ title: "首页", anchor: "", isOpen: false, ref: 'home'},
				{ title: "汽车电子服务", anchor: "", isOpen: false, ref: 'car', children: [
            {title: '自研产品', anchor: 'car-develop'},
            {title: '汽车电子软件开发工程服务和咨询服务', anchor: 'car1'},
            {title: '汽车电子测试领域人力外包服务', anchor: 'car2'},
            {title: '零部件级别信息安全测试', anchor: 'car3'},
            {title: '汽车电子领域软件技术培训', anchor: ''},
          ]},
				{ title: "应用软件服务", anchor: "", isOpen: false, ref: 'web', children: [
            {title: '自研产品', anchor: '', url: '/doc'}
          ]},
				{ title: "小插件", anchor: "", isOpen: false, ref: 'plugin', url: '/convert'},
				{ title: "联系我们", anchor: "", isOpen: false, ref: 'contact', url: '/contact'}
			],

			jituanList: [
				{
					jituanimg: require("../assets/img/jituan1.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "金融产业",
					jituanp: "融资租赁  |  债券融资  |  信托  |  产业基金",
				},
				{
					jituanimg: require("../assets/img/jituan1.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "金融产业",
					jituanp: "融资租赁  |  债券融资  |  信托  |  产业基金",
				},
				{
					jituanimg: require("../assets/img/jituan1.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "金融产业",
					jituanp: "融资租赁  |  债券融资  |  信托  |  产业基金",
				},
				{
					jituanimg: require("../assets/img/jituan1.png"),
					jituanimgyinying: require("../assets/img/jituanyinying.png"),
					jituanspan: "金融产业",
					jituanp: "融资租赁  |  债券融资  |  信托  |  产业基金",
				},
			],
			logoList: [
				{ logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/hengrun.png") },
        { logo: require("../assets/img/company/jl-university.jpg") },
        { logo: require("../assets/img/company/jl-university.jpg") },
			],
			newList: [
				{
					title: "发展数字经济 共享包容增长 为经济发展插上“数字翅膀”",
					time: "2021-05-30",
					text: "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
				},
				{
					title: "用系统思维下好“职业教育一盘大棋”",
					time: "2021-05-30",
					text: "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
				},
				{
					title: "我国将在全国实施动产和权利担保统一登记—拓融资渠道",
					time: "2021-05-30",
					text: "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
				},
			],

      navItemVisible: false,
      scrollVisible: false,
      scrollTrigger: false,
      scrollTop: 0,
      closePng,
      listPng,
      arrowUp,
      arrowDown
		};
	},
	computed: {
		title() {
			return this.$store.state.title;
		},
	},
  mounted() {
	  // let that = this
	  // this.navList.forEach((item) =>{
	  //   item.height = that.$refs[item.ref].scrollHeight
    //   that.$refs[item.ref].style.height = '0'
    // })
    // console.log(that.navList)
    let anchor = this.$route.params.anchor;
    console.log('anchor===', anchor, this.$route.params)
    if (anchor){
      setTimeout(() =>{
        this.scrollToAnchor(anchor)
      }, 200)
    }
    window.addEventListener('scroll', this.getScrollTop)
  },
  methods: {
    getScrollTop(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let that = this; // 防止this指向问题
      // 设备一屏的高度
      let clientHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      // let scrollTop = e.srcElement.scrollTop;
      this.scrollTop = scrollTop;
      // 判断距离顶部多少显示回到顶部图标
      if (scrollTop > clientHeight) {
        that.scrollVisible = true;
      } else {
        that.scrollVisible = false;
      }
    },
    toDoc(){
      this.$router.push({
        path: '/doc'
      })
    },
    toConvert(){
      this.$router.push({
        path: '/convert'
      })
    },
    toContact(){
      this.$router.push('/contact')
    },
	  scrollToAnchor(selector, url = ''){
      console.log('selector', selector)
      if (url){
        this.$router.push(url)
        return
      }
	    if (!selector){
	      return
      }
	    this.navItemVisible = false
      document.querySelector('#'+selector)
          .scrollIntoView({
        behavior: "smooth"
      });
    },
    toggleNav(item){
		  item.isOpen = !item.isOpen
      if (item.url){
        this.$router.push(item.url)
      }
      // if (item.isOpen){
      //   this.$refs[item.ref].style.height = item.height + 'px'
      // }else {
      //   this.$refs[item.ref].style.height = '0'
      // }
    },
    toggleNavSlide(){
      this.navItemVisible = !this.navItemVisible
    },
    toNews(index){
      console.log('news,index', index)
      this.$router.push({
        path: '/newsxiangqing0',
        query:{
          index
        }
      })
    }
	},
  destroyed() {
    window.removeEventListener('scroll', this.getScrollTop);
  }
};
</script>
<style scoped>

.p-title{
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  margin: 10px 0;
}

.p-content{
  text-align: left;
  color: #888;
}

.p-detail{
  margin-top: 50px;
  text-align: right;
  color: #888;
}

.back-top:hover{
  cursor: pointer;
}

.back-top img{
  width: 80px;
}


@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
  width: 100vw;
  margin: 0 auto;
  /*height: 1000px;*/
}

.banner_nav {
  width: 100vw;
  height: 800px;
  position: relative;
}

.banner_nav_bg img {
  /* 文本居顶部对齐 */
  vertical-align: top;
}


.banner_title_center div span {
  margin: 0 18px;
}

/* news */
.news {
  width: 100vw;
  background: #f8f8f8;
}

.news_cont_left_text h1 {
  font-size: 18px;
  font-weight: 500;
  color: #23337f;
}

.news_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  margin-top: 10px;
}

.news_cont_right_ul ul a {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}

.news_cont_right_ul ul li:hover {
  color: #23337f;
}

.news_cont_right_ul ul span {
  margin: 0 23px 0 24px;
}

.news_cont_right_text_item h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}

.news_cont_right_text_item p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
}

.news_cont_right_text_item2 h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}

.news_cont_right_text_item2 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
}

.GroupIndustry_cont_left_img {
  margin-bottom: 24px;
  position: relative;
}

.GroupIndustry_cont_left_text {
  position: absolute;
  left: 32px;
  bottom: 18px;
}

.GroupIndustry_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 10px;
}

.GroupIndustry_cont_left_text span {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

/* 合作伙伴 */
.CooperativePartner {
  width: 100vw;
  background-image: url("../assets/img/hezuo_bg.png");
}

.CooperativePartner_cont_center_item {
  display: flex;
  align-items: center;
}

.CooperativePartner_cont_center_item:hover {
  border-bottom: 1px solid #3491e5;
}

.CooperativePartner_cont_center_item img {
  width: 40px;
  height: 40px;
}

.CooperativePartner_cont_center_item span {
  line-height: 69px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 33px;
  margin-left: 14px;
}

/* pc端 */
@media only screen and (min-width: 800px){

  .margin-top-70{
    margin-top: 70px;
  }

  .module-flex{
    display: flex;
  }

  .module-flex h3{
    margin-left: 20px;
  }

  .module-area{
    width: 100vw;
    background-color: #f8f8f8;
    padding: 89px 0 0;
  }

  .module-body{
    width: 1200px;
    margin: 0 auto;
  }

  .module-content{
    margin-top: 41px;
    background-color: #FFFFFF;
    display: flex;
  }

  .module-image-area{
    width: 50%;
    text-align: center;
  }

  .module-image-title{
    color: #0053a5;
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 1px;
  }

  .module-image-subTitle{
    font-size: 12px;
    color: #7f7f7f;
    margin-bottom: 10px;
  }

  .module-image{
    width: 550px;
    box-shadow: 0 5px 30px -10px rgba(0,0,0,.37);
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto 30px;
  }

  .module-image img{
    width: 550px;
  }

  .module-text-list h3{
    width: 120px;
  }

  .module-text-list h6{
    color: #888;
    width: 380px;
    margin-left: 30px;
    line-height: 25px
  }

  .dropbtn {
    background-color: #fff;
    color: rgb(12, 11, 11);
    padding: 16px;
    font-size: 16px;
    width: 180px;
    border: none;
    cursor: pointer;
  }

  .dropdown-software{
    top: 22px;
    /*left: 604px;*/
    position: relative;
    display: inline-block;
  }

  .dropdown-plugin{
    top: 22px;
    /*left: 554px;*/
    position: relative;
    display: inline-block;
  }

  .dropdown {
    top: 22px;
    /*left: 654px;*/
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    text-align: center;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 180px;
    min-width: 95px;
    z-index: 999;
    /*min-height: 120px;*/
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .dropdown-content .dropdown-item {
    color: rgb(6, 6, 6);
    padding: 12px 16px;
    text-decoration: none;
    word-wrap: break-word;
    display: block;
  }

  .dropdown-content .dropdown-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  .dropdown-software:hover .dropdown-content {
    display: block;
  }

  .dropdown-plugin:hover .dropdown-content {
    display: block;
  }

  /* 导航部分 */
  .navWrap {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*  */
    padding-top: 40px;
  }

  .navWrap-mobile{
    display: none;
  }

  .navWrap_center {
    width: 85%;
    height: 101px;
    margin: 0 auto;
    background: #fff;
    border-radius: 51px;
    /*  */
    display: flex;
    justify-content: space-between;
  }

  .navWrap_center_img {
    width: 181px;
    height: 54px;
    margin: 15px 0 0 120px;
  }

  .navWrap_center_img img {
    width: 181px;
  }

  .navWrap_center_ul {
    /* border:1px solid red; */
    margin-right: 121px;
  }

  .navWrap_center_ul li {
    display: inline-block;
    height: 98px;
    line-height: 98px;
    /* border-bottom: 4px solid blue; */
    margin-left: 50px;
    padding: 0 5px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    /* line-height: 22px; */
  }

  .navWrap_center_ul li:hover {
    cursor: pointer;
    color: rgba(35, 51, 127, 1);
    border-bottom: 2px solid rgba(35, 51, 127, 1);
  }

  .banner_title_center {
    /* border: 1px solid white; */
    text-align: center;
    width: 1200px;
    margin: 0 auto;
    font-size: 90px;
    font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
    font-weight: bold;
    color: #ffffff;
    line-height: 124px;
    letter-spacing: 1px;
  }

  /* title */
  .banner_title_wrap {
    width: 100%;
    position: absolute;
    bottom: 290px;
    /* border: 1px solid yellow; */
  }

  .banner_title_center div {
    padding-top: 14px;
    font-size: 20px;
    font-family: Helvetica;
    line-height: 24px;
    letter-spacing: 4px;
  }

  .news_cont {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .news_cont_left {
    margin-top: 89px;
  }

  .news_cont_left_img img {
    width: 588px;
    height: 353px;
    margin-top: 41px;
  }

  .news_cont_left_text {
    padding-top: 27px;
    padding-left: 40px;
    width: 548px;
    height: 150px;
    background-color: #ffffff;
  }

  .news_cont_right {
    width: 588px;
    margin-top: 103px;
  }

  .news_cont_right_ul ul {
    font-size: 16px;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 22px;
    float: right;
    display: flex;
  }

  .news_cont_right_text {
    width: 588px;
    height: 535px;
    background: #ffffff;
    margin-top: 68px;
  }

  .news_cont_right_text_hr {
    /* float: right; */
    width: 508px;
    border-bottom: 1px solid #d7d7d7;
  }

  .news_cont_right_text_item {
    padding: 36px;
  }

  .news_cont_right_text_hr:nth-of-type(3) {
    /* float: right; */
    width: 508px;
    border-bottom: none;
  }

  .GroupIndustry_cont {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  /* 集团产业 */
  .GroupIndustry {
    width: 100vw;
    background: #ffffff;
  }

  .GroupIndustry_cont_left_title {
    /*font-size: 36px;*/
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #444444;
    line-height: 50px;
    margin: 84px 0 40px 0;
  }

  .GroupIndustry_cont_left_img_f {
    width: 793px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .GroupIndustry_cont_left_img img:first-child {
    width: 384px;
    height: 261px;
    background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
  }

  .GroupIndustry_cont_left_img2 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .GroupIndustry_cont_right {
    position: relative;
    margin-top: 98px;
  }

  /* 关于企业 */
  .AboutEnterprises {
    width: 100vw;
    height: 856px;
    background-image: url("../assets/img/guanyu_bg.png");
  }

  .AboutEnterprises_cont {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
  }

  .AboutEnterprises_cont_top_right {
    position: relative;
    width: 487px;
    height: 517px;
    margin-top: 115px;
  }

  .AboutEnterprises_cont_top {
    display: flex;
    justify-content: space-between;
  }

  .AboutEnterprises_cont_top_left {
    margin-top: 143px;
    width: 600px;
  }

  .AboutEnterprises_cont_top_left > h1 {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
  }

  .AboutEnterprises_cont_top_left p {
    margin-top: 36px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
  }

  .AboutEnterprises_cont_top_left_btn {
    width: 160px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    margin-top: 81px;
    display: flex;
    align-items: center;
  }

  .GroupIndustry_cont_right_title {
    float: right;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    margin-bottom: 54px;
  }

  .GroupIndustry_cont_right_img {
    width: 384px;
    height: 546px;
  }

  .GroupIndustry_cont_right_img2 {
    position: absolute;
    bottom: 24px;
  }

  .GroupIndustry_cont_right_text {
    position: absolute;
    left: 45px;
    bottom: 45px;
  }

  .GroupIndustry_cont_right_text p {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
  }

  .GroupIndustry_cont_right_text span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }

  .AboutEnterprises_cont_top_right img {
    position: absolute;
    z-index: 1;
  }

  .AboutEnterprises_cont_top_right Div {
    width: 317px;
    height: 408px;
    background: #3491e5;
    position: absolute;
    top: 64px;
    left: -33px;
  }

  .AboutEnterprises_cont_top_left_btn img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }

  .AboutEnterprises_cont_top_left_btn span {
    margin-left: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
  }

  .AboutEnterprises_cont_bottom {
    display: flex;
    margin-top: 80px;
  }

  .AboutEnterprises_cont_bottom_item {
    margin-right: 50px;
    position: relative;
    width: 247px;
    border-right: 1px solid #ffffff;
    display: flex;
  }

  .AboutEnterprises_cont_bottom_item3 p {
    width: 86px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
  }

  .AboutEnterprises_cont_bottom_item3 h1 {
    font-size: 60px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 84px;
  }

  .AboutEnterprises_cont_bottom_item3 span {
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }

  .AboutEnterprises_cont_bottom_item h1 {
    font-size: 60px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 84px;
  }

  .AboutEnterprises_cont_bottom_item span {
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }

  .AboutEnterprises_cont_bottom_item p {
    width: 86px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 28px;
    margin-top: 30px;
    margin-left: 20px;
  }

  .AboutEnterprises_cont_bottom_item2 {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .CooperativePartner_cont {
    width: 1200px;
    margin: 0 auto;
  }

  .CooperativePartner_cont_title {
    text-align: center;
    margin-top: 80px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #444444;
    line-height: 50px;
  }

  .CooperativePartner_cont_center {
    display: flex;
    justify-content: space-between;
    width: 365px;
    height: 69px;
    margin: 0 auto;
    margin-top: 47px;
  }

  .CooperativePartner_cont_bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
  }

  .CooperativePartner_cont_bottom_logo img {
    width: 227px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
    border-radius: 9px;
    margin-bottom: 16px;
  }

  .padding-text{
    text-align: left;
    padding: 60px 30px;
  }

  .padding-60{
    padding: 0 60px;
  }

  .margin-top-30-percent{
    margin-top: 30%;
  }

  .back-top{
    position: fixed;
    bottom: 100px;
    right: 60px;
    width: 80px;
    height: 80px;
    z-index: 9999999;
  }
}

/* 小于800px 移动端 */
@media only screen and (max-width: 800px){

  .padding-15{
    padding: 15px;
  }

  .padding-top-20{
    padding-top: 20px;
  }

  .margin-top-70{
    margin-top: 20px;
  }

  /* 导航部分 */
  .navWrap {
    display: none;
  }

  .navWrap-mobile{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /*height: 100vh;*/
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 999;
  }

  .navWrap-bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    line-height: 60px;
    background-color: rgba(0, 0, 0, .4);
    border-bottom-color: transparent;
    /*z-index: 999;*/
    box-shadow: 0 8px 8px 0 rgba(0,0,0,.03);
    display: flex;
  }

  .nav-img-side{
    width: 60px;
  }

  .nav-img-side img{
    width: 30px;
    margin: 15px;
  }

  .nav-img-center{
    text-align: center;
    width: calc(100vw - 120px);
  }

  .nav-img-center img{
    width: 120px;
  }

  .nav-position{
    position: absolute;
    top: 61px;
    left: 0;
    right: 0;
  }

  .nav-item-content{
    height: calc(100vh - 61px);
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .nav-item-content ul{
    list-style: none;
    margin: 0;
    padding: 0;
    border: medium none;
  }

  .nav-item-text{
    float: none;
    list-style: none;
    background-color: #fff;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .nav-link{
    width: 100%;
    /*text-align: center;*/
    height: 50px;
    line-height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;
    outline: none;
    text-decoration: none;
    color: #2e2e2e;
  }

  .nav-title{
    text-align: left;
  }

  .nav-img img{
    width: 20px;
    margin: 15px;
  }

  .nav-dropdown{
    display: block;
    visibility: visible;
    top: 100%;
    opacity: 1;
    width: 100%;
    position: static;
    border: 0 none;
    left: 110px;
    transition: all .3s;
  }

  .nav-dropdown-item{
    border-bottom: 1px solid #f5f5f5;
    position: relative;
    line-height: 48px;
    padding-left: 30px;
  }

  .nav-item-text{
    margin: 0 30px;
    /*!*height: 40px;*!*/
    border-bottom: 1px solid #d7d7d7;
  }

  .banner_nav{
    height: 35vh;
  }

  .banner_nav_bg{
    height: 100%;
  }

  .banner_nav_bg img{
    width: 100%;
    height: 100%;
  }

  .navWrap_center {
    width: 85%;
    height: 60px;
    margin: 0 auto;
    background: #fff;
    border-radius: 51px;
    /*  */
    display: flex;
    justify-content: space-between;
  }

  .navWrap_center_img {
    margin:13px;
  }

  .navWrap_center_img img {
    width: 90px;
  }

  .navWrap_center_ul {
    /* border:1px solid red; */
    margin-right: 15px;
  }

  .navWrap_center_ul li {
    display: inline-block;
    height: 60px;
    line-height: 60px;
    /* border-bottom: 4px solid blue; */
    margin-left: 20px;
    padding: 0 5px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    /* line-height: 22px; */
  }

  .banner_title_center {
    /* border: 1px solid white; */
    text-align: center;
    margin: 0 auto;
    font-size: 35px;
    font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
    font-weight: bold;
    color: #ffffff;
    line-height: 70px;
    letter-spacing: 1px;
  }

  /* title */
  .banner_title_wrap {
    width: 100%;
    position: absolute;
    top: 40%;
    /* border: 1px solid yellow; */
  }

  .banner_title_center div {
    font-size: 20px;
    font-family: Helvetica;
    line-height: 24px;
  }

  .news_cont {
    width: 90vw;
    margin: 0 auto;
  }

  .news_cont_left {
    padding-top: 29px;
  }

  .news_cont_left_img img {
    width: 90vw;
    margin-top: 36px;
  }

  .news_cont_left_text {
    padding: 10px;
    width: 85vw;
    height: 150px;
    background-color: #ffffff;
  }

  .news_cont_right{
    padding-top: 10px;
    background-color: #FFFFFF;
    border-top: 1px solid #d7d7d7;
  }

  .news_cont_right_ul ul {
    font-size: 16px;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 22px;
    display: flex;
    justify-content: center;
  }

  .news_cont_right_text {
    width: 90vw;
    background: #ffffff;
  }

  .news_cont_right_text_hr {
    /* float: right; */
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    border-bottom: 1px solid #d7d7d7;
  }


  .module-area{
    width: 100vw;
    background-color: #f8f8f8;
    padding: 29px 0 0;
  }

  .module-body{
    width: 90vw;
    margin: 0 auto;
  }

  .module-content{
    margin-top: 36px;
    background-color: #FFFFFF;
    /*display: flex;*/
  }

  .module-image-area{
    width: 100%;
    text-align: center;
    padding: 1px 0;
  }

  .module-image-title{
    color: #0053a5;
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0 1px;
  }

  .module-image-subTitle{
    font-size: 12px;
    color: #7f7f7f;
    margin-bottom: 10px;
  }

  .padding-60{
    padding: 30px 0;
  }

  .padding-text{
    text-align: left;
  }

  .padding-text h2{
    margin: 10px;
  }

  .module-image{
    width: 90%;
    box-shadow: 0 5px 30px -10px rgba(0,0,0,.37);
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto 20px;
  }

  .module-image img{
    width: 80vw;
  }

  .module-text-list h3{
    margin: 10px;
    /*width: 120px;*/
  }

  .module-text-list h6{
    color: #888;
    /*width: 380px;*/
    /*margin-left: 30px;*/
    margin: 10px;
    line-height: 25px
  }

  /*.news_cont_right_text_item {*/
  /*  padding: 12px;*/
  /*}*/

  /* 集团产业 */
  .GroupIndustry {
    width: 100vw;
    background: #f8f8f8;
  }

  .GroupIndustry_cont {
    width: 90vw;
    margin: 0 auto;
    background-color: #FFFFFF;
  }

  .GroupIndustry_cont_left_title {
    /*font-size: 36px;*/
    /*font-family: PingFangSC-Semibold, PingFang SC;*/
    /*font-weight: 600;*/
    /*color: #444444;*/
    line-height: 50px;
    background-color: #f8f8f8;
    padding: 29px 0;
  }

  .GroupIndustry_cont_left_img_f {
    width: 90vw;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*flex-wrap: wrap;*/
  }

  .GroupIndustry_cont_left_img img:first-child {
    width: 90vw;
    background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
  }

  .GroupIndustry_cont_left_img2 {
    position: absolute;
    width: 90vw;
    left: 0;
    bottom: 0;
  }

  .GroupIndustry_cont_right {
    display: none;
  }

  /* 关于企业 */
  .AboutEnterprises {
    width: 90vw;
    margin: 0 auto;
    background-image: url("../assets/img/guanyu_bg.png");
  }

  .AboutEnterprises_cont {
    width: 100%;
    height: 100%;
  }

  .AboutEnterprises_cont_top_right {
   display: none;
  }

  .AboutEnterprises_cont_top_left > h1 {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
    padding: 20px;
  }

  .AboutEnterprises_cont_top_left p {
    margin: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }

  .AboutEnterprises_cont_top_left_btn {
    width: 160px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    margin: 20px;
    display: flex;
    align-items: center;
  }

  .AboutEnterprises_cont_top_left_btn span {
    margin-left: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
  }

  .AboutEnterprises_cont_bottom {
    display: flex;
  }

  .AboutEnterprises_cont_bottom_item {
    /*margin-right: 10px;*/
    position: relative;
    width: 25%;
    border-right: 1px solid #ffffff;
    display: flex;
  }

  .AboutEnterprises_cont_bottom_item3 p {
    width: 86px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
  }

  .AboutEnterprises_cont_bottom_item3 h1 {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 84px;
  }

  .AboutEnterprises_cont_bottom_item3 span {
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }

  .AboutEnterprises_cont_bottom_item h1 {
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding-left: 5px;
    color: #ffffff;
    line-height: 64px;
  }

  .AboutEnterprises_cont_bottom_item span {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }

  .AboutEnterprises_cont_bottom_item p {
    width: 86px;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 1px;
    margin-top: 25px;
    margin-left: 5px;
  }

  .AboutEnterprises_cont_bottom_item2 {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .CooperativePartner_cont_title {
    text-align: center;
    margin-top: 40px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #444444;
    line-height: 50px;
  }

  .CooperativePartner_cont_center {
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 69px;
    margin: 0 auto;
    margin-top: 7px;
  }

  .CooperativePartner_cont_bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90vw;
    margin: 0 auto;
    margin-top: 10px;
  }

  .CooperativePartner_cont_bottom_logo img {
    width: 40vw;
    height: 17vw;
    background: #ffffff;
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
    border-radius: 9px;
    margin-bottom: 13px;
  }

  .back-top{
    position: fixed;
    bottom: 100px;
    right: 60px;
    width: 40px;
    height: 40px;
    z-index: 9999999;
  }
}


</style>
