<template>
  <div>
    <!-- 导航栏 -->
    <header-vue @toggleBar="toggleBar"></header-vue>

    <!-- body -->
    <div class="body" :style="{'margin-top': barVisible ? '60px' : 0}">
      <!--   动图   -->
      <div class="animate-div" :style="{ opacity: navItemVisible? 0: 1 }">
        <div class="wrapper">
          <h1>高效实用的文档管理工具</h1>
          <div class="input-div">
            <input type="text" class="user-phone-number" v-model="form.mobile" autofocus placeholder="请输入手机号"/>
            <a class="btn" @click="toCDOT">免费使用</a>
          </div>
<!--          <div class="demo-account"><span >用户名、密码：trial/123456</span></div>-->
          <div class="project-manage-animate-box">
            <div class="project-manage-animate">
              <img class="project-manage-animate-bg"
                   src="../assets/img/doc-bg.png" alt="动画背景">
              <div class="animate-gear-box">
                <div class="animate-gear">
                  <div class="animate-gear-img-box">
                    <img class="animate-gear-img"
                         src="../assets/img/gear.png" alt="大齿轮">
                  </div>
                </div>
                <div class="animate-gear-sm">
                  <div class="animate-gear-sm-img-box">
                    <img class="animate-gear-sm-img"
                         src="../assets/img/gear-small.png" alt="小齿轮">
                  </div>
                </div>
              </div>
              <div class="animate-block-box">
                <div class="animate-block"><img class="animate-block-img"
                                                src="../assets/img/square.png"
                                                alt="块儿"></div>
              </div>
              <div class="animate-triangle-box">
                <div class="animate-triangle"><img class="animate-triangle-img"
                                                   src="../assets/img/triangle.png"
                                                   alt="三角"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  图片文字结构 需求可重用  -->
      <div class="wrapper">
        <div class="content-flex">
          <div class="case-overview-right" style="text-align: right">
            <h4 class="case-overview-title">需求可重用</h4>
            <h4 class="case-overview-sub-title">Reuse of Requirement</h4>
            <div class="case-overview-des">独立的需求条目，原子化  存储管理；需求可重用和引用；可追溯性</div>
          </div>
          <div class="case-overview-left">
            <div class="img-view">
              <img src="../assets/img/doc/doc-edit.png"/>
            </div>
          </div>
        </div>

      </div>

      <!--  视频文字  集中结构化存储  -->
      <div class="wrapper">
        <div class="pc-video content-flex">
          <div class="case-overview-left">
            <div class="video-div">
              <video id="video-player-work-flow_html5_api" class="vjs-tech" tabindex="-1" role="application" loop="" muted="muted" autoplay="" src="../assets/video/doc-video.mp4"></video>
            </div>
          </div>
          <div class="case-overview-right" style="margin-left: 70px">
            <h4 class="case-overview-title">集中结构化存储</h4>
            <h4 class="case-overview-sub-title">Centralized Structured Storage</h4>
            <div class="case-overview-des">文档机构化 条理化，纲领化；互相关联，层次结构；数据库管理，具有操作规范；可实现智能化升级</div>
          </div>
        </div>

        <div class="mobile-video content-flex">
          <div class="case-overview-right">
            <h4 class="case-overview-title">集中结构化存储</h4>
            <h4 class="case-overview-sub-title">Centralized Structured Storage</h4>
            <div class="case-overview-des">文档机构化 条理化，纲领化；互相关联，层次结构；数据库管理，具有操作规范；可实现智能化升级</div>
          </div>
          <div class="case-overview-left">
            <div class="video-div">
              <video id="video-player-work-flow_html5_api_mobile" class="vjs-tech" tabindex="-1" role="application" loop="" muted="muted" autoplay="" src="../assets/video/doc-video.mp4"></video>
            </div>
          </div>
        </div>
      </div>

      <!--  视频文字  工程化管理  -->
      <div class="wrapper">
        <div class="content-flex">

          <div class="case-overview-right" style="text-align: right;">
            <h4 class="case-overview-title">工程化管理</h4>
            <h4 class="case-overview-sub-title">Engineering Management</h4>
            <div class="case-overview-des">严谨的工程目录结构。文档业务流转；文档流程监管；自动化生产配置文件及资源文件等</div>
          </div>

          <div class="case-overview-left">
            <div class="video-div">
              <video id="video-player-work-flow_html5_api1" class="vjs-tech" tabindex="-1" role="application" loop="" muted="muted" autoplay="" src="../assets/video/doc-video2.mp4"></video>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- footer -->
    <footer2></footer2>

    <Dialog v-if="isMask" :propsItem="propsItem" @child-ok="childOk" @child-cancel="childCancel">
      <template>
        <div class="el-form-item is-required" :class="{'is-error' : realNameError}">
          <label class="el-form-item__label form-label">联系人</label>
          <div class="el-form-item__content">
            <div class="el-input"><input type="text" v-model="form.realName" autocomplete="off" class="el-input__inner form-input-height" @blur="checkInput('realName')"></div>
            <div v-show="realNameError" class="el-form-item__error form-error-tip">请输入联系人姓名</div>
          </div>
        </div>

        <div class="el-form-item is-required" :class="{'is-error' : mobileError}">
          <label class="el-form-item__label form-label">电话</label>
          <div class="el-form-item__content">
            <div class="el-input"><input type="text" v-model="form.mobile" autocomplete="off" class="el-input__inner form-input-height" @blur="checkInput('mobile')"></div>
            <div v-show="mobileError" class="el-form-item__error form-error-tip">手机号格式不正确</div>
          </div>
        </div>

        <div class="el-form-item is-required" :class="{'is-error' : emailError}">
          <label class="el-form-item__label form-label">邮箱</label>
          <div class="el-form-item__content">
            <div class="el-input"><input type="text" v-model="form.email" autocomplete="off" class="el-input__inner form-input-height" @blur="checkInput('email')"></div>
            <div v-show="emailError" class="el-form-item__error form-error-tip">邮箱格式不正确</div>
          </div>
        </div>

        <div class="el-form-item is-required" :class="{'is-error' : companyError}">
          <label class="el-form-item__label form-label">工作单位</label>
          <div class="el-form-item__content">
            <div class="el-input"><input type="text" v-model="form.company" autocomplete="off" class="el-input__inner form-input-height" @blur="checkInput('company')"></div>
            <div v-show="companyError" class="el-form-item__error form-error-tip">请输入所属单位</div>
          </div>
        </div>

        <div class="el-form-item is-required" :class="{'is-error' : jobError}">
          <label class="el-form-item__label form-label">职位</label>
          <div class="el-form-item__content">
            <div class="el-input"><input type="text" v-model="form.job" autocomplete="off" class="el-input__inner form-input-height" @blur="checkInput('job')"></div>
            <div v-show="jobError" class="el-form-item__error form-error-tip">请输入职位名称</div>
          </div>
        </div>

      </template>
    </Dialog>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import closePng from '../assets/img/close.png';
import listPng from '../assets/img/list.png';
import arrowUp from '../assets/img/arrow-up.png'
import arrowDown from '../assets/img/arrow-down.png'
import Dialog from "../components/dialog";
import headerVue from "../components/header";
import axios from "axios";
// import { baseUrl } from '../../vue.config'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

export default {
  name: "doc",
  data(){
    return{
      navList: [
        { title: "首页", anchor: "", isOpen: false, ref: 'home', url: '/'},
        { title: "汽车电子服务", anchor: "", isOpen: false, ref: 'car', children: [
            {title: '自研产品', anchor: 'car-develop'},
            {title: '汽车电子软件开发工程服务和咨询服务', anchor: 'car1'},
            {title: '汽车电子测试领域人力外包服务', anchor: 'car2'},
            {title: '零部件级别信息安全测试', anchor: 'car3'},
            {title: '汽车电子领域软件技术培训', anchor: ''},
          ]},
        { title: "应用软件服务", anchor: "", isOpen: false, ref: 'web', children: [
            {title: '自研产品', anchor: '', url: '/doc'}
          ]},
        { title: "小插件", anchor: "", isOpen: false, ref: 'plugin'},
        { title: "联系我们", anchor: "", isOpen: false, ref: 'contact', url: '/contact'}
      ],
      navItemVisible: false,
      scrollVisible: false,
      scrollTrigger: false,
      scrollTop: 0,
      isPc: document.body.clientWidth >= 800,
      clientWidth: document.body.clientWidth,
      isMask: false,
      propsItem:{
        title:'标题',
        contents:'',
        ok:'确认',
        cancel:'取消',
        isMask:true,
        rgba:false,
        widthHeight:{
          width: document.body.clientWidth >= 800 ? '400px' : '80%',
          height:'auto'
        }
      },
      form:{
        realName: '',
        mobile: '',
        email: '',
        company: '',
        job: ''
      },
      realNameError: false,
      mobileError: false,
      emailError: false,
      companyError: false,
      jobError: false,
      barVisible: false,
      closePng,
      listPng,
      arrowUp,
      arrowDown
    }
  },
  components: {
    footer2,
    Dialog,
    headerVue
  },
  mounted() {
    let width = document.body.clientWidth
    console.log("屏幕宽度为：", width)
  },
  methods:{
    checkInput(type){
      if (type === 'all' || type === 'mobile'){
        this.mobileError = !this.validatePhoneNumber(this.form.mobile)
      }else if (type === 'all' || type === 'realName'){
        this.realNameError = this.form.realName === ''
      }else if (type === 'all' || type === 'email'){
        this.emailError = !this.validateEmail(this.form.email)
      }else if (type === 'all' || type === 'company'){
        this.companyError = this.form.company === ''
      }else if (type === 'all' || type === 'job'){
        this.jobError = this.form.job === ''
      }
    },
    // 校验手机号
    validatePhoneNumber(number){
      if (!number){
        return false
      }
      let regex = /^1[3456789]\d{9}$/;
      return regex.test(number);
    },
    // 校验邮箱
    validateEmail(email){
      if (!email){
        return false
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    childOk(){
      this.checkInput('all')
      if (this.mobileError || this.realNameError || this.emailError || this.companyError || this.jobError){
        return;
      }
      // axios.defaults.baseURL = baseUrl
      axios.post('/api/cdot/apply', this.form).then(response =>{
        console.log(response)
        alert('申请成功，请注意邮箱查收体验账号信息')
      })
      this.propsItem.isMask = false
    },
    childCancel(){
    },
    toIndex(){
      this.$router.push('/')
    },
    toContact(){
      this.$router.push('/contact')
    },
    toCDOT(){
      this.propsItem.isMask = this.isMask = true
      // window.open('http://cdot-beta.jlricker.com/')
    },
    toggleNav(item){
      item.isOpen = !item.isOpen
      console.log("item", item)
      if (item.url !== undefined){
        this.$router.push(item.url)
      }
      // if (item.isOpen){
      //   this.$refs[item.ref].style.height = item.height + 'px'
      // }else {
      //   this.$refs[item.ref].style.height = '0'
      // }
    },
    toggleBar(barVisible){
      this.barVisible = barVisible
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 22px;
  display: flex;
}

.el-form-item__label {
  width: 28%;
  text-align: left;
  vertical-align: middle;
  color: #606266;
  line-height: 40px;
  /*padding: 0 12px 0 0;*/
  box-sizing: border-box;
}

label {
  cursor: default;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.el-form-item__content {
  width: 80%;
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.el-form-item__content:after, .el-form-item__content:before {
  display: table;
  content: "";
}

.el-form-item__content:after {
  clear: both;
}

.el-form-item__content:after, .el-form-item__content:before {
  display: table;
  content: "";
}

.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
}

.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.el-input::-webkit-scrollbar {
  z-index: 11;
  width: 6px;
}

.el-input::-webkit-scrollbar-corner, .el-input::-webkit-scrollbar-track {
  background: #fff;
}

.el-input::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  background: #b4bccc;
}

.el-input::-webkit-scrollbar-corner, .el-input::-webkit-scrollbar-track {
  background: #fff;
}

.el-input::-webkit-scrollbar-track-piece {
  background: #fff;
  width: 6px;
}

.el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
  border-color: #f56c6c;
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  outline: none;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

input{
  cursor: pointer;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.el-form-item__error {
  color: #f56c6c;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

/* pc端 */
@media only screen and (min-width: 800px){
  .form-error-tip{
    font-size: 12px;
  }

  .form-input-height{
    height: 35px;
    line-height: 35px;
  }

  .form-label{
    font-size: 14px;
  }
  /* 导航部分 */
  .navWrap {
    display: block;
  }

  .navWrap-mobile{
    display: none;
  }

  .demo-account{
    color: red;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 16px;
    z-index: 999;
  }


  .video-div{
    width: 630px;
    height: 470px;
  }

  .case-overview-right .case-overview-des {
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    color: #888;
    letter-spacing: 0;
    line-height: 24px;
    text-align: justify;
  }

  .case-overview-right .case-overview-title {
    margin-bottom: 6px;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 38px;
    color: #333;
    letter-spacing: 0;
    line-height: 1;
  }

  .case-overview-sub-title{
    margin-bottom: 36px;
    color: #2382c9;
    font-size: 20px;
  }

  .img-view{
    width: 591px;
    /*height: 385px;*/
    box-shadow: 0 5px 30px -10px rgba(0, 0, 0, .37);
    box-sizing: border-box;
    /*width: 100%;*/
    border-radius: 5px;
    overflow: hidden;
  }

  .img-view img{
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .case-overview-left {
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .case-overview-right {
    margin-right: 66px;
    box-sizing: border-box;
    width: 380px;
  }


  .project-manage-animate-box .project-manage-animate .animate-gear-box .animate-gear {
    margin-left: 36px;
    margin-right: 36px;
    animation: gear-rotate 6s cubic-bezier(0.41, 0.12, 0.55, 0.85) 0s infinite;
  }

  @keyframes gear-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .project-manage-animate-box .project-manage-animate .animate-triangle-box .animate-triangle {
    position: relative;
    left: 388px;
    transform: translateY(0);
    animation: triangle-run 5s ease-in-out infinite alternate;
  }

  @keyframes triangle-run {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(80px);
    }
  }

  .project-manage-animate-box .project-manage-animate .animate-triangle-box {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 500ms linear;
  }

  .project-manage-animate-box .project-manage-animate .animate-block-box .animate-block {
    position: relative;
    left: -302px;
    transform: translateY(0);
    animation: block-run 3s ease-in-out infinite alternate;
  }

  @keyframes block-run {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(130px);
    }
  }

  .project-manage-animate-box .project-manage-animate .animate-block-box {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 400ms linear;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box .animate-gear-sm {
    margin-left: 52px;
    animation: gear-rotate 4s cubic-bezier(0.39, 0.16, 0.58, 0.86) 0s infinite;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box .animate-gear {
    margin-left: 36px;
    margin-right: 36px;
    animation: gear-rotate 6s cubic-bezier(0.41, 0.12, 0.55, 0.85) 0s infinite;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box, .project-manage-animate-box .project-manage-animate .animate-block-box, .project-manage-animate-box .project-manage-animate .animate-triangle-box {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box {
    display: flex;
    align-items: center;
    justify-content: center;
    top: -70px;
  }


  .project-manage-animate-box {
    padding-top: 25px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .project-manage-animate-box .project-manage-animate {
    min-height: 303px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-manage-animate-box .project-manage-animate .project-manage-animate-bg {
    width: 857px;
    position: relative;
    z-index: 5;
  }

  canvas, img, video {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .nav-bar {
    width: 100vw;
    height: 80px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 10px 40px -15px rgba(64, 90, 163, .15);
    box-sizing: border-box;
  }

  .nav-logo {
    line-height: 80px;
    margin-left: 100px;
  }

  .nav-logo img {
    margin-top: 6px;
    width: 160px;
  }

  .nav-content {
    display: flex;
  }

  .dropdown {
    top: 12px;
    /*left: 654px;*/
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    text-align: center;
    display: none;
    position: absolute;
    z-index: 99;
    background-color: #f9f9f9;
    width: 180px;
    min-width: 95px;
    /*min-height: 120px;*/
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .dropdown-content .dropdown-item {
    color: rgb(6, 6, 6);
    padding: 12px 16px;
    text-decoration: none;
    word-wrap: break-word;
    display: block;
  }

  .dropdown-content .dropdown-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropbtn {
    background-color: #fff;
    color: rgb(12, 11, 11);
    padding: 16px;
    font-size: 16px;
    width: 180px;
    border: none;
    cursor: pointer;
  }

  .body {
    width: 100vw;
  }

  .animate-div{
    background-color: #f6faff;
    width: 100vw;
  }

  .wrapper {
    width: 1200px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 108px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .content-flex{
    padding: 100px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper h1 {
    font-weight: 400;
    line-height: 70px;
    text-align: center;
    font-size: 56px;
    color: #485778;
    position: relative;
    z-index: 9;
  }

  .input-div {
    margin-top: 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 11;
    height: 48px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-phone-number {
    padding-left: 12px;
    box-sizing: border-box;
    width: 232px;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    position: relative;
    left: 1px;
  }

  .user-phone-number a {
    padding: 0 35px;
    box-sizing: border-box;
    height: 100%;
    line-height: 46px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: none;
    position: relative;
    z-index: 2;
  }

  .btn {
    display: inline-block;
    padding: 0 26px;
    height: 46px;
    line-height: 46px;
    font-size: 1rem;
    transition: 300ms;
    font-weight: 400;
    color: #fff;
    background-color: #348fe4;
    border: 1px solid #348fe4;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    border-radius: 0 4px 4px 0;
  }

  .pc-video{
    display: flex;
  }

  .mobile-video{
    display: none;
  }
}

/* 移动端 */
@media only screen and (max-width: 800px){
  .form-error-tip{
    font-size: 11px;
  }

  .form-input-height{
    height: 30px;
    line-height: 30px;
  }

  .form-label{
    font-size: 12px;
  }
  /* 导航部分 */
  .navWrap {
    display: none !important;
  }

  .navWrap-mobile{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 999;
  }

  .navWrap-bar{
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    width: 100vw;
    height: 60px;
    line-height: 60px;
    background-color: rgba(0, 0, 0, .4);
    border-bottom-color: transparent;
    /*z-index: 999;*/
    box-shadow: 0 8px 8px 0 rgba(0,0,0,.03);
    display: flex;
  }

  .nav-img-side{
    width: 60px;
  }

  .nav-img-side img{
    width: 30px;
    margin: 15px;
  }

  .nav-img-center{
    text-align: center;
    width: calc(100vw - 120px);
  }

  .nav-img-center img{
    width: 120px;
  }

  .nav-item-content{
    position: absolute;
    top: 61px;
    left: 0;
    right: 0;
    height: calc(100vh - 61px);
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .nav-item-content ul{
    list-style: none;
    margin: 0;
    padding: 0;
    border: medium none;
  }

  .nav-item-text{
    float: none;
    list-style: none;
    background-color: #fff;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .nav-link{
    width: 100%;
    /*text-align: center;*/
    height: 50px;
    line-height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;
    outline: none;
    text-decoration: none;
    color: #2e2e2e;
  }

  .nav-title{
    text-align: left;
  }

  .nav-img img{
    width: 20px;
    margin: 15px;
  }

  .nav-dropdown{
    display: block;
    visibility: visible;
    top: 100%;
    opacity: 1;
    width: 100%;
    position: static;
    border: 0 none;
    left: 110px;
    transition: all .3s;
  }

  .nav-dropdown-item{
    border-bottom: 1px solid #f5f5f5;
    position: relative;
    line-height: 48px;
    padding-left: 30px;
  }

  .nav-item-text{
    margin: 0 30px;
    /*!*height: 40px;*!*/
    border-bottom: 1px solid #d7d7d7;
  }


  .demo-account{
    color: red;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 16px;
    z-index: 9;
  }


  .video-div{
    width: 90vw;
    /*height: 470px;*/
  }

  .case-overview-right .case-overview-des {
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    color: #888;
    padding: 10px 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: justify;
  }

  .case-overview-right .case-overview-title {
    margin-bottom: 6px;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 38px;
    color: #333;
    letter-spacing: 0;
    line-height: 1;
    padding: 0 20px;
  }

  .case-overview-sub-title{
    padding: 0 20px;
    margin-bottom: 16px;
    color: #2382c9;
    font-size: 20px;
  }

  .img-view{
    width: 90vw;
    /*height: 385px;*/
    box-shadow: 0 5px 30px -10px rgba(0, 0, 0, .37);
    box-sizing: border-box;
    /*width: 100%;*/
    border-radius: 5px;
    overflow: hidden;
  }

  .img-view img{
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .case-overview-left {
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .case-overview-right {
    /*margin-left: 70px;*/
    box-sizing: border-box;
    width: 100vw;
  }


  .project-manage-animate-box .project-manage-animate .animate-gear-box .animate-gear {
    margin-left: 36px;
    margin-right: 36px;
    animation: gear-rotate 6s cubic-bezier(0.41, 0.12, 0.55, 0.85) 0s infinite;
  }

  @keyframes gear-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .project-manage-animate-box .project-manage-animate .animate-triangle-box .animate-triangle {
    position: relative;
    left: 108px;
    top: 60px;
    width: 8%;
    transform: translateY(0);
    animation: triangle-run 5s ease-in-out infinite alternate;
  }

  @keyframes triangle-run {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(80px);
    }
  }

  .project-manage-animate-box .project-manage-animate .animate-triangle-box {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 500ms linear;
  }

  .project-manage-animate-box .project-manage-animate .animate-block-box .animate-block {
    position: relative;
    width: 8%;
    left: -175px;
    transform: translateY(0);
    animation: block-run 3s ease-in-out infinite alternate;
  }

  @keyframes block-run {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(130px);
    }
  }

  .project-manage-animate-box .project-manage-animate .animate-block-box {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 400ms linear;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box .animate-gear-sm {
    margin-left: 42px;
    animation: gear-rotate 4s cubic-bezier(0.39, 0.16, 0.58, 0.86) 0s infinite;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box .animate-gear {
    margin-left: 6px;
    margin-right: 6px;
    animation: gear-rotate 6s cubic-bezier(0.41, 0.12, 0.55, 0.85) 0s infinite;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box, .project-manage-animate-box .project-manage-animate .animate-block-box, .project-manage-animate-box .project-manage-animate .animate-triangle-box {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .project-manage-animate-box .project-manage-animate .animate-gear-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    left: 8%;
    top: 80px;
  }


  .project-manage-animate-box {
    padding-top: 25px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .project-manage-animate-box .project-manage-animate {
    min-height: 303px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-manage-animate-box .project-manage-animate .project-manage-animate-bg {
    width: 80%;
    position: relative;
    z-index: 5;
  }

  canvas, img, video {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .nav-bar {
    width: 100vw;
    height: 80px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 10px 40px -15px rgba(64, 90, 163, .15);
    box-sizing: border-box;
  }

  .nav-logo {
    line-height: 80px;
    margin-left: 100px;
  }

  .nav-logo img {
    margin-top: 6px;
    width: 160px;
  }

  .nav-content {
    display: flex;
  }

  .dropdown {
    top: 12px;
    /*left: 654px;*/
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    text-align: center;
    display: none;
    position: absolute;
    z-index: 99;
    background-color: #f9f9f9;
    width: 180px;
    min-width: 95px;
    /*min-height: 120px;*/
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .dropdown-content .dropdown-item {
    color: rgb(6, 6, 6);
    padding: 12px 16px;
    text-decoration: none;
    word-wrap: break-word;
    display: block;
  }

  .dropdown-content .dropdown-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropbtn {
    background-color: #fff;
    color: rgb(12, 11, 11);
    padding: 16px;
    font-size: 16px;
    width: 180px;
    border: none;
    cursor: pointer;
  }

  .body {
    width: 100vw;
  }

  .animate-div{
    background-color: #f6faff;
    width: 100vw;
    /*margin-top: 60px;*/
  }

  .wrapper {
    width: 100vw;
    margin: 0 auto;
    /*padding: 108px 0 0;*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .content-flex{
    padding: 100px 0;
    box-sizing: border-box;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
  }

  .wrapper h1 {
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    font-size: 26px;
    color: #485778;
    position: relative;
    /*z-index: 9;*/
  }

  .input-div {
    margin-top: 40px;
    box-sizing: border-box;
    position: relative;
    /*z-index: 11;*/
    height: 38px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-phone-number {
    padding-left: 12px;
    box-sizing: border-box;
    width: 232px;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    position: relative;
    left: 1px;
  }

  .user-phone-number a {
    padding: 0 35px;
    box-sizing: border-box;
    height: 100%;
    line-height: 46px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: none;
    position: relative;
    z-index: 2;
  }

  .btn {
    display: inline-block;
    padding: 0 26px;
    height: 36px;
    line-height: 36px;
    font-size: 1rem;
    transition: 300ms;
    font-weight: 400;
    color: #fff;
    background-color: #348fe4;
    border: 1px solid #348fe4;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    border-radius: 0 4px 4px 0;
  }

  .pc-video{
    display: none;
  }

  .mobile-video{
    display: block;
  }
}


</style>
