<template>
	<div>
		<!-- ---------- -->

		<div class="footer_warp">
			<div class="footer_cont">
				<!-- 上部 -->
				<div class="footer_cont_l">
					<!--上部 左 -->
					<div class="footer_cont_l_div">
						<div class="footer_cont_l_div_item">
							<p class="footer_cont_l_title">企业概况</p>
							<ul class="footer_cont_l_ul">
								<li
									v-for="(item, index) in arrList"
									:key="index"
									@click="toGuid(item.url, item.id)"
								>
									{{ item.title }}
								</li>

								<!-- <li>企业荣誉</li> -->
							</ul>
						</div>
						<div class="footer_cont_l_div_item">
							<p class="footer_cont_l_title">集团产业</p>
							<ul class="footer_cont_l_ul">
								<li
									v-for="(item, index) in arrList2"
									:key="index"
									@click="toPages(item.url)"
								>
									{{ item.title }}
								</li>
							</ul>
						</div>
						<div class="footer_cont_l_div_item">
							<p class="footer_cont_l_title">企业新闻</p>
							<ul class="footer_cont_l_ul">
								<li
									v-for="(item, index) in arrList3"
									:key="index"
									@click="toNews(item.url)"
								>
									{{ item.title }}
								</li>
							</ul>
						</div>
						<div class="footer_cont_l_div_item2">
							<p class="footer_cont_l_title">人力资源</p>
							<ul class="footer_cont_l_ul">
								<router-link to="zhaopinxinxi0"><li>招聘信息</li></router-link>
							</ul>
						</div>
					</div>
				</div>
				<!-- top 中 -->
				<div class="footer_cont_c">
					<div>
						<img style="width: 220px" src="../assets/img/logo.png" alt="" />
					</div>
					<div>
						<p class="address">
							公司总部地址：长春市高新区博才路168号吉林大学科技产业园<br />
							邮箱：jlrk@jlricker.com
						</p>
					</div>
				</div>
				<!-- top 右 -->
				<div class="footer_cont_r">
<!--					<div>-->
<!--						<div class="footer_cont_r_border">-->
<!--							<img src="../assets/img/ewm2.png" alt="" />-->
<!--						</div>-->
<!--						<p>关注微信公众号</p>-->
<!--					</div>-->
				</div>
			</div>
			<!-- 下部 -->
			<div class="footer_warp_btm">
				<p>
          Copyright © 吉林省睿库信息科技有限公司   #吉ICP备2023007901号#
        </p>
			</div>
		</div>

    <div class="footer_wrap_mobile">
      <div class="logo-div">
        <img src="../assets/img/logo.png" alt="" />
      </div>

      <div class="dropdown-div">
        <div class="dropdown-title" @click="toggleDiv('companyInfo')">
          <div>企业概况</div>
          <div><img :src="companyInfoCollapse ? arrowDown : arrowUp"/></div>
        </div>
        <div class="dropdown-content" ref="companyInfo">
          <div class="dropdown-item">关于企业</div>
          <div class="dropdown-item">企业实力</div>
          <div class="dropdown-item">发展历程</div>
        </div>

        <div class="dropdown-title" @click="toggleDiv('companyProducts')">
          <div>集团产业</div>
          <div><img :src="companyProductsCollapse ? arrowDown : arrowUp"/></div>
        </div>
        <div class="dropdown-content" ref="companyProducts">
          <div class="dropdown-item">金融板块</div>
          <div class="dropdown-item">科技板块</div>
          <div class="dropdown-item">电商板块</div>
          <div class="dropdown-item">职教板块</div>
          <div class="dropdown-item">幼教板块</div>
        </div>

        <div class="dropdown-title" @click="toggleDiv('companyNews')">
          <div>企业新闻</div>
          <div><img :src="companyNewsCollapse ? arrowDown : arrowUp"/></div>
        </div>
        <div class="dropdown-content" ref="companyNews">
          <div class="dropdown-item">集团要闻</div>
          <div class="dropdown-item">媒体报道</div>
          <div class="dropdown-item">行业资讯</div>
        </div>

        <div class="dropdown-title" @click="toggleDiv('companyJob')">
          <div>人力资源</div>
          <div><img :src="companyJobCollapse ? arrowDown : arrowUp"/></div>
        </div>
        <div class="dropdown-content" ref="companyJob">
          <div class="dropdown-item" @click="toRecruit">招聘信息</div>
        </div>

        <div class="dropdown-title">
          <div>长春市高新区博才路168号吉林大学科技产业园</div>
        </div>


        <div class="dropdown-title" style="margin: 20px 0">
          <div>jlrk@jlricker.com</div>
        </div>


        <div class="company-right border-div">
          Copyright © 吉林省睿库信息科技有限公司
        </div>
        <div class="company-right bottom-div">
          #吉ICP备2023007901号#
        </div>
      </div>


    </div>



		<!-- --------- -->
	</div>
</template>

<script>
import arrowUp from '../assets/img/arrow-up.png'
import arrowDown from '../assets/img/arrow-down.png'
export default {
	name: "Footer",
	data() {
		return {
			arrList: [
				{
					title: "关于企业",
					url: "/contact",
					id: "about",
				},
				{
					title: "企业实力",
					url: "/jituangaikuo",
					id: "CultureInfo",
				},
				{
					title: "发展历程",
					url: "/jituangaikuo",
					id: "history",
				},
			],
			arrList2: [
				{
					title: "金融板块",
					url: "/jinrong",
				},
				{
					title: "科技板块",
					url: "/keji",
				},
				{
					title: "电商板块",
					url: "/dianshang",
				},
				{
					title: "职教板块",
					url: "/zhijiao",
				},
				{
					title: "幼教板块",
					url: "/youjiao",
				},
			],
			arrList3: [
				{
					title: "集团要闻",
					url: "/xinwen",
				},
				{
					title: "媒体报道",
					url: "/xinwen/MediaCoverage",
				},
				{
					title: "行业资讯",
					url: "/xinwen/TradeNews",
				},
			],
      arrowUp,
      arrowDown,
      companyInfoCollapse: true,
      companyInfoHeight: 0,
      companyProductsCollapse: true,
      companyProductsHeight: 0,
      companyNewsCollapse: true,
      companyNewsHeight: 0,
      companyJobCollapse: true,
      companyJobHeight: 0,
		};
	},

	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});

    // this.$refs['companyInfo'].style.height = this.$refs['companyInfo'].scrollHeight + 'px'
    this.companyInfoHeight = this.$refs['companyInfo'].scrollHeight
    this.$refs['companyInfo'].style.height = '0'
    this.companyProductsHeight = this.$refs['companyProducts'].scrollHeight
    this.$refs['companyProducts'].style.height = '0'
    this.companyNewsHeight = this.$refs['companyNews'].scrollHeight
    this.$refs['companyNews'].style.height = '0'
    this.companyJobHeight = this.$refs['companyJob'].scrollHeight
    this.$refs['companyJob'].style.height = '0'
	},

	methods: {
    toggleDiv(prop){
      if (prop === 'companyInfo'){
        this.companyInfoCollapse = !this.companyInfoCollapse
        if (this.companyInfoCollapse){
          this.$refs['companyInfo'].style.height = '0'
        }else {
          // this.$refs['companyInfo'].style.height = this.$refs['companyInfo'].scrollHeight + 'px'
          this.$refs['companyInfo'].style.height = this.companyInfoHeight + 'px'
        }
      }else if (prop === 'companyProducts'){
        this.companyProductsCollapse = !this.companyProductsCollapse
        if (this.companyProductsCollapse){
          this.$refs['companyProducts'].style.height = '0'
        }else {
          this.$refs['companyProducts'].style.height = this.companyProductsHeight + 'px'
        }
      }else if (prop === 'companyNews'){
        this.companyNewsCollapse = !this.companyNewsCollapse
        if (this.companyNewsCollapse){
          this.$refs['companyNews'].style.height = '0'
        }else {
          this.$refs['companyNews'].style.height = this.companyNewsHeight + 'px'
        }
      }else if (prop === 'companyJob'){
        this.companyJobCollapse = !this.companyJobCollapse
        if (this.companyJobCollapse){
          this.$refs['companyJob'].style.height = '0'
        }else {
          this.$refs['companyJob'].style.height = this.companyJobHeight + 'px'
        }
      }
    },
		// 锚点跳转
		toGuid(path, id) {
      console.log(path)
			localStorage.setItem("toId", id);
			// this.$router.push(path);
			//查找存储的锚点id
			let Id = localStorage.getItem("toId");
			let toElement = document.getElementById(Id);
			//锚点存在跳转
			if (Id) {
				toElement.scrollIntoView();
				localStorage.setItem("toId", "");
			}
		},

		toPages(url) {
      console.log(url)
			// this.$router.push(url);
		},
		toNews(url) {
      console.log(url)
			// this.$router.push(url);
		},
    toRecruit(){
      this.$router.push('/zhaopinxinxi0')
    }
	},
};
</script>
<style scoped>

@media only screen and (max-width: 800px) {
  .footer_warp {
    display: none;
  }

  .footer_wrap_mobile {
    width: 100vw;
    border-top: 1px solid #d7d7d7;
    position: absolute;
    z-index: 99999
  }

  .logo-div img{
    width: 25vw;
    margin: 20px;
  }

  .dropdown-div{
    margin: 0 34px 10px;
  }

  .dropdown-title{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    /*color: #dddddd;*/
    line-height: 22px;
  }

  .dropdown-title img{
    width: 15px;
  }

  .dropdown-content{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    /*color: #999999;*/
    margin: 10px 25px;
    transition: height 0.28s;
    overflow: hidden;
  }

  .dropdown-item{
    height: 20px;
    margin: 10px 0;
  }

  .border-div{
    border-top: 1px solid #d7d7d7;
  }

  .company-right{
    padding-top: 10px;
    /*color: #999999;*/
    font-size: 14px;
    text-align: center;
  }

  .bottom-div{
    padding-bottom: 20px;
  }
}


@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/
/* pc端 */
@media only screen and (min-width: 800px){
  .footer_warp {
    width: 100vw;
    height: 388px;
    background: #17213d;
  }

  .footer_wrap_mobile {
    display: none;
  }
}


.footer_cont {
	width: 1200px;
	margin: 0 auto;
	display: flex;
}
.footer_cont_l {
	font-size: 16px;
	font-weight: 500;
	color: #dddddd;
	line-height: 22px;
	padding-top: 77px;
}
.footer_cont_l_title {
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #dddddd;
	line-height: 22px;
	width: 64px;
}
.footer_cont_l_ul {
	margin-top: 36px;
}
.footer_cont_l_ul li {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	margin-top: 10px;
	cursor: pointer;
}
.footer_cont_l_div {
	display: flex;
}
.footer_cont_l_div_item {
	margin-right: 72px;
}
.footer_cont_c {
	margin-top: 70px;
	margin-left: 80px;
}
.address {
	width: 467px;
	font-size: 16px;
	font-family: "PingFangSC-Regular", "PingFang SC";
	font-weight: 400;
	color: #ffffff;
	line-height: 32px;
	margin-top: 42px;
}
.footer_cont_r {
	margin-top: 79px;
	margin-left: 70px;
}
.footer_cont_r p {
	text-align: center;
	font-size: 14px;
	font-family: "PingFangSC-Regular";
	font-weight: 400;
	color: #ffffff;
	line-height: 20px;
	margin-top: 17px;
}
.footer_cont_r_border {
	width: 111px;
	height: 109px;
	border: 1px solid #979797;
}
.footer_cont_r_border img {
	width: 95px;
	height: 95px;
	margin: 7px 8px;
}
.footer_warp_btm {
	width: 1280px;
	margin: 0 auto;
	height: 65px;
	margin-top: 56px;

	border-top: 1px solid #444444;
}
.footer_warp_btm p {
	font-size: 12px;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 48px;
}
</style>
