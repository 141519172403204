<template>
<div class="bg-color mobile-bottom">
  <!-- 导航栏 -->
  <header-vue @toggleBar="toggleBar"></header-vue>
  <h1 class="base-title" :style="{'margin-top': barVisible ? '60px' : 0}">转换列表</h1>

  <div class="content-div" v-for="(item, index) in convertList" :key="item.title">
    <div class="flex-div" v-if="index%2===0">
      <div class="module-div" @click="toConvertDetail(item.param)" >
        <div class="img-div">
          <img class="module-img" src="../../assets/img/convert.png"/>
        </div>
        <div class="detail-div">
          <div class="module-title">{{item.title}}</div>
          <div class="module-content">{{item.content}}</div>
          <div class="module-link"><span class="link-text">查看详情</span><span class="link-symbol">></span></div>
        </div>
      </div>

      <div class="module-div" @click="toConvertDetail(convertList[index+1].param)" v-if="index+1<convertList.length">
        <div class="img-div">
          <img class="module-img" src="../../assets/img/convert.png"/>
        </div>
        <div class="detail-div">
          <div class="module-title">{{convertList[index+1].title}}</div>
          <div class="module-content">{{convertList[index+1].content}}</div>
          <div class="module-link"><span class="link-text">查看详情</span><span class="link-symbol">></span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-div" style="width: 100vw; height: 1px;"></div>

  <!-- footer -->
  <footer2></footer2>
</div>
</template>

<script>
import headerVue from "../../components/header";
import footer2 from "../../components/footer.vue";

export default {
  name: "index",
  components: {
    headerVue,
    footer2
  },
  data(){
    return{
      convertList: [
        {title: 'dbc转excel', content: '将dbc文件转换成excel格式的文件', param: 'dbcToExcel'},
        {title: 'dbc转arxml', content: '将dbc文件转换成arxml格式的文件', param: 'dbcToArxml'},
        {title: 'excel转dbc', content: '将excel文件转换成dbc格式的文件', param: 'excelToDbc'},
        {title: 'excel转arxml', content: '将excel文件转换成arxml格式的文件', param: 'excelToArxml'},
        {title: 'arxml转dbc', content: '将arxml文件转换成dbc格式的文件', param: 'arxmlToDbc'},
        {title: 'arxml转excel', content: '将arxml文件转换成Excel格式的文件', param: 'arxmlToExcel'},
      ],
      barVisible: false,
    }
  },
  methods:{
    toggleBar(barVisible){
      this.barVisible = barVisible
    },
    toConvertDetail(param){
      this.$router.push({
        path: `/convert/${param}/detail`,
      })
    }
  }
}
</script>

<style scoped>
.bg-color{
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, #DCE5F0 0%, #F2F5FA 100%);
}

.base-title{
  padding: 72px 0 54px;
  color: #030a1a;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
}

.content-div{
  width: 70vw;
  margin: 0 auto;
}


.img-div{
  background-color: #dcdfe5;
  margin-right: 15px;
  width: 200px;
  text-align: center;
}

.detail-div{
  position: relative;
}

.module-title{
  padding-top: 8px;
  color: #030a1a;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px
}

.module-content{
  padding-top: 18px;
  line-height: 18px;
  overflow: hidden;
  color: #858c99;
  font-size: 12px;
  text-align: justify;
  position: relative;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.module-link{
  position: absolute;
  bottom: 8px;
  left: 0;
  line-height: 20px;
  color: #1a66ff;
}

.link-symbol{
  margin-left: 10px;
}

.link-text{
  font-size: 14px;
}

.link-text:hover{
  text-decoration: underline;
}

.bottom-div{
  margin-top: 30px
}

/* pc端 */
@media only screen and (min-width: 800px){
  .flex-div{
    display: flex;
    justify-content: space-around;
  }

  .module-div{
    width: 600px;
    height: 150px;
    padding: 15px;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 20px;
  }

  .module-img{
    width: 130px;
    margin: 10px;
  }
}

/* 移动端 */
@media only screen and (max-width: 800px){
  .module-div{
    height: 120px;
    padding: 15px;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 20px;
  }

  .module-img{
    width: 100px;
    margin: 10px;
  }
}
</style>
