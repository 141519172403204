<template>
<div>
  <!-- 导航栏 -->
  <div v-if="isPc" class="nav-bar navWrap">
    <!--  logo  -->
    <div class="nav-logo">
      <img src="../assets/img/logo.png"/>
    </div>
    <!--  导航  -->
    <div class="nav-content">
      <div class="dropdown">
        <button class="dropbtn" @click="toIndex">首页</button>
      </div>
      <div class="dropdown">
        <button class="dropbtn">汽车电子服务</button>
        <div class="dropdown-content">
          <div class="dropdown-item" @click="toIndexAnchor('car-develop')">自研产品</div>
          <div class="dropdown-item" @click="toIndexAnchor('car1')">汽车电子软件开发工程服务和咨询服务</div>
          <div class="dropdown-item" @click="toIndexAnchor('car2')">汽车电子测试领域人力外包服务</div>
          <div class="dropdown-item" @click="toIndexAnchor('car3')">零部件级别信息安全测试</div>
          <div class="dropdown-item" @click="toIndex">汽车电子领域软件技术培训</div>
        </div>
      </div>

      <div class="dropdown">
        <button class="dropbtn">应用软件服务</button>
        <div class="dropdown-content">
          <div class="dropdown-item" @click="toDoc">自研产品</div>
        </div>
      </div>

      <div class="dropdown">
        <button class="dropbtn" @click="toConvert">小插件</button>
        <!--          <div class="dropdown-content">-->
        <!--            <div class="dropdown-item">待定</div>-->
        <!--            <div class="dropdown-item">待定</div>-->
        <!--            <div class="dropdown-item">待定</div>-->
        <!--            <div class="dropdown-item">待定</div>-->
        <!--          </div>-->
      </div>

      <div class="dropdown">
        <button class="dropbtn" @click="toContact">联系我们</button>
      </div>

    </div>
  </div>

  <div v-show="!isPc && !navItemVisible">
    <div class="navWrap-bar">
      <div class="nav-img-side" @click="toggleBar"><img :src="navItemVisible ? closePng : listPng"></div>
      <div class="nav-img-center"><img src="../assets/img/logo.png"></div>
      <div class="nav-img-side"></div>
    </div>
  </div>
  <div v-if="!isPc && navItemVisible" class="navWrap-mobile">
    <div class="navWrap-bar">
      <div class="nav-img-side" @click="toggleBar"><img :src="navItemVisible ? closePng : listPng"></div>
      <div class="nav-img-center"><img src="../assets/img/logo.png"></div>
      <div class="nav-img-side"></div>
    </div>
    <div class="nav-item-content" v-show="navItemVisible">
      <ul>
        <li v-for="(item) in navList" :key="item.title" class="nav-item-text">
          <div class="nav-link" @click="toggleNav(item)">
            <div class="nav-title">{{item.title}}</div>
            <div class="nav-img" v-if="item.children"><img :src="item.isOpen ? arrowUp : arrowDown"/></div>
          </div>
          <ul class="nav-dropdown" ref="{{item.ref}}" v-show="item.isOpen">
            <li v-for="child in item.children" :key="child.title" class="nav-dropdown-item" @click="toggleNav(child)">
              {{child.title}}
            </li>
          </ul>
        </li>
      </ul>

    </div>

  </div>
</div>
</template>

<script>
import closePng from '../assets/img/close.png';
import listPng from '../assets/img/list.png';
import arrowUp from '../assets/img/arrow-up.png'
import arrowDown from '../assets/img/arrow-down.png'

export default {
  name: "header",
  data(){
    return{
      isPc: document.body.clientWidth >= 800,
      clientWidth: document.body.clientWidth,
      navItemVisible: false,
      navList: [
        { title: "首页", anchor: "", isOpen: false, ref: 'home', url: '/'},
        { title: "汽车电子服务", anchor: "", isOpen: false, ref: 'car', children: [
            {title: '自研产品', componentName: 'index', url: '/', anchor: 'car-develop'},
            {title: '汽车电子软件开发工程服务和咨询服务', componentName: 'index', url: '/', anchor: 'car1'},
            {title: '汽车电子测试领域人力外包服务', componentName: 'index', url: '/', anchor: 'car2'},
            {title: '零部件级别信息安全测试', componentName: 'index', url: '/', anchor: 'car3'},
            {title: '汽车电子领域软件技术培训', componentName: 'index', url: '/', anchor: ''},
          ]},
        { title: "应用软件服务", anchor: "", isOpen: false, ref: 'web', children: [
            {title: '自研产品', anchor: '', url: '/doc'}
          ]},
        { title: "小插件", anchor: "", isOpen: false, ref: 'plugin', url: '/convert'},
        { title: "联系我们", anchor: "", isOpen: false, ref: 'contact', url: '/contact'}
      ],
      closePng,
      listPng,
      arrowUp,
      arrowDown
    }
  },
  methods:{
    toIndex(){
      this.$router.push('/')
    },
    toIndexAnchor(anchor){
      // localStorage.setItem('index-anchor', anchor);
      this.$router.push({
        path: '/',
        name: 'index',
        params: {
          anchor
        }
      })
    },
    toContact(){
      this.$router.push('/contact')
    },
    toDoc(){
      this.$router.push('/doc')
    },
    toConvert(){
      this.$router.push('/convert')
    },
    toggleNav(item){
      item.isOpen = !item.isOpen
      console.log("item", item)
      if (item.url !== undefined){
        if (item.anchor){
          this.$router.push({
            path: item.url,
            name: item.componentName,
            params:{
              anchor: item.anchor
            }
          })
        }else {
          this.$router.push(item.url)
        }

      }
      // if (item.isOpen){
      //   this.$refs[item.ref].style.height = item.height + 'px'
      // }else {
      //   this.$refs[item.ref].style.height = '0'
      // }
    },
    toggleBar(){
      this.navItemVisible = !this.navItemVisible
      this.$emit('toggleBar', this.navItemVisible)
    }
  }
}
</script>

<style scoped>

.nav-bar {
  width: 100vw;
  height: 80px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 40px -15px rgba(64, 90, 163, .15);
  box-sizing: border-box;
}

.nav-logo {
  line-height: 80px;
  margin-left: 100px;
}

.nav-logo img {
  margin-top: 6px;
  width: 160px;
}

.nav-content {
  display: flex;
}

.dropdown {
  top: 12px;
  /*left: 654px;*/
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #fff;
  color: rgb(12, 11, 11);
  padding: 16px;
  font-size: 16px;
  width: 180px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  z-index: 99;
  background-color: #f9f9f9;
  width: 180px;
  min-width: 95px;
  /*min-height: 120px;*/
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content .dropdown-item {
  color: rgb(6, 6, 6);
  padding: 12px 16px;
  text-decoration: none;
  word-wrap: break-word;
  display: block;
}

.dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-dropdown{
  display: block;
  visibility: visible;
  top: 100%;
  opacity: 1;
  width: 100%;
  position: static;
  border: 0 none;
  left: 110px;
  transition: all .3s;
}

.nav-dropdown-item{
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  line-height: 48px;
  padding-left: 30px;
}

.navWrap-bar{
  /*position: fixed;*/
  /*top: 0;*/
  /*left: 0;*/
  width: 100vw;
  height: 60px;
  line-height: 60px;
  background-color: rgba(0, 0, 0, .4);
  border-bottom-color: transparent;
  /*z-index: 999;*/
  box-shadow: 0 8px 8px 0 rgba(0,0,0,.03);
  display: flex;
}

.nav-img-side{
  width: 60px;
}

.nav-img-side img{
  width: 30px;
  margin: 15px;
}

.nav-img-center{
  text-align: center;
  width: calc(100vw - 120px);
}

.nav-img-center img{
  width: 120px;
}

.nav-item-content{
  position: absolute;
  top: 61px;
  left: 0;
  right: 0;
  height: calc(100vh - 61px);
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-item-content ul{
  list-style: none;
  margin: 0;
  padding: 0;
  border: medium none;
}

.nav-item-text{
  float: none;
  list-style: none;
  background-color: #fff;
  position: relative;
  margin: 0;
  padding: 0;
}

.nav-item-text{
  margin: 0 30px;
  /*!*height: 40px;*!*/
  border-bottom: 1px solid #d7d7d7;
}

.nav-link{
  width: 100%;
  /*text-align: center;*/
  height: 50px;
  line-height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  outline: none;
  text-decoration: none;
  color: #2e2e2e;
}

.nav-title{
  text-align: left;
}

.nav-img img{
  width: 20px;
  margin: 15px;
}

/* pc端 */
@media only screen and (min-width: 800px){
  .navWrap {
    display: flex;
  }

  .navWrap-mobile{
    display: none;
  }

}

/* 移动端 */
@media only screen and (max-width: 800px){
  .navWrap {
    display: none !important;
  }

  .navWrap-mobile{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 999;
  }
}


</style>
