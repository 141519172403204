<template>
<div>
  <!-- 导航栏 -->
  <header-vue @toggleBar="toggleBar"/>

  <div class="bg-title" :style="{'margin-top': barVisible ? '60px' : 0}">
    <div class="text-title">企业新闻</div>
    <div class="text-content">更多最新精彩资讯，感受企业魅力</div>
  </div>

<!--  <div class="banner_warp"  :style="{'margin-top': barVisible ? '60px' : 0}">-->
<!--    <img src="../assets/img/矿山banner1@2x.png" class="banner_warp_img"/>-->
<!--    <div class="banner_warp_text">-->
<!--      <h1>企业新闻</h1>-->
<!--      <p>更多最新精彩资讯，感受企业魅力</p>-->
<!--    </div>-->
<!--  </div>-->

  <div class="Crumbs mobile-crumbs">
    当前位置：首页><span style="color: #000000">联系我们</span>
  </div>

  <div class="talk_top">
    <div class="talk_top_cont">
      <div class="talk_top_cont_l">
        <h1>联系我们</h1>
        <p>CONTACT US</p>
      </div>
      <div class="mobile-contact">
        <div class="talk_top_cont_c">
          <div class="talk_top_cont_c_b">
            <div class="talk_top_cont_c_b_l">
              <p>热线电话</p>
              <p>TEL 135 7879 6479</p>
            </div>
            <div class="talk_top_cont_c_b_r">
              <p>联系地址</p>
              <p>长春市高新区博才路168号吉林大学科技园1层</p>
            </div>
          </div>
        </div>
        <div>
          <img src="../assets/img/wechat.jpg" style="width: 90px"/>
        </div>
      </div>

    </div>
  </div>

  <div class="talk_bottom">
    <img src="../assets/img/map.jpg"/>
  </div>

  <footer2/>
</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import headerVue from "../components/header";

export default {
  name: "contact",
  components:{
    footer2,
    headerVue
  },
  data(){
    return{
      barVisible: false,
    }
  },
  methods:{
    toIndex(){
      this.$router.push('/')
    },
    toDoc(){
      this.$router.push('/doc')
    },
    toggleBar(barVisible){
      this.barVisible = barVisible
    }
  }
}
</script>

<style scoped>

.bg-title{
  background: url("../assets/img/矿山banner1@2x.png") no-repeat;
  background-size: auto 100%;
  width: 100vw;
  height: 300px;
  text-align: center;
  /*line-height: 300px;*/
  color: #FFFFFF;
}

.text-title{
  padding-top: 105px;
  font-size: 40px;
}

.text-content{
  margin-top: 20px;
}

.talk_bottom {
  width: 1200px;
  margin: 91px auto;
  margin-bottom: 81px;
}

.talk_top_cont_c {
  display: flex;
  width: 487px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444;
  line-height: 25px;
}

.talk_top_cont_l p {
  width: 235px;
  height: 80px;
  font-size: 48px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f6f6f6;
  line-height: 40px;
}

.talk_top_cont_l>h1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #23337f;
  line-height: 45px;
}

.talk_top_cont_l {
  width: 235px;
  height: 134px;
}

.talk_top_cont {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  padding-top: 90px;
  margin: 0 auto;
}

.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  line-height: 46px;
}

.banner_warp_text p{
  margin-top: 18px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 25px;
}

.banner_warp_text h1 {
  text-align: center;
  padding-top: 112px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
}

.banner_warp_text {
  position: absolute;
  left: 845px;
  top: 11px;
}

.banner_warp_img {
  width: 100vw;
  z-index: -999;
  position: absolute;
  height: 300px;
}

.banner_warp {
  position: relative;
  width: 100vw;
  height: 300px;
  margin: 0 auto;
}

.nav-bar {
  width: 100vw;
  height: 80px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 40px -15px rgba(64, 90, 163, .15);
  box-sizing: border-box;
}

.nav-logo {
  line-height: 80px;
  margin-left: 100px;
}

.nav-logo img {
  margin-top: 6px;
  width: 160px;
}

.nav-content {
  display: flex;
}

.dropdown {
  top: 12px;
  /*left: 654px;*/
  position: relative;
  display: inline-block;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  z-index: 99;
  background-color: #f9f9f9;
  width: 180px;
  min-width: 95px;
  /*min-height: 120px;*/
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content .dropdown-item {
  color: rgb(6, 6, 6);
  padding: 12px 16px;
  text-decoration: none;
  word-wrap: break-word;
  display: block;
}

.dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn {
  background-color: #fff;
  color: rgb(12, 11, 11);
  padding: 16px;
  font-size: 16px;
  width: 180px;
  border: none;
  cursor: pointer;
}

.mobile-contact{
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 800px){
  .mobile-crumbs{
    width: 100vw;
    padding-left: 20px;
  }

  .talk_top_cont{
    width: 100vw;
    padding-top: 20px;
  }

  .talk_bottom{
    width: 90vw;
  }

  .talk_bottom img{
    width: 90vw;
  }

  .talk_top_cont{
    display: block;
  }

  .mobile-contact{
    margin: 20px 20px 0;
  }

  .talk_top_cont_l{
    margin-left: 20px;
  }

  .mobile-contact img{
    margin-top: 15px;
  }

}

</style>
