<template>
<div>
  <!-- 导航栏 -->
  <header-vue @toggleBar="toggleBar"></header-vue>

  <div :style="{'margin-top': barVisible ? '60px' : 0}" style="width: 100vh; height: 1px"></div>
  <div v-if="convertStatus === 0">
    <div class="top-row">
      <img class="img-item" src="../../assets/img/file-red.png"/>
      <img class="img-item mid-symbol" src="../../assets/img/transform.png"/>
      <img class="img-item" src="../../assets/img/file-blue.png"/>
    </div>

    <div class="bold-tips">点击上传文件</div>
    <div class="grey-tips">可支持上传{{allowFileType}}文件</div>
    <div class="bold-tips check-tips" v-show="errorFileTypeFlag">
      <span><img class="check-img" src="../../assets/img/error.png"/></span><span class="check-text">文件格式不正确</span>
    </div>
    <div class="upload-div">
      <div class="upload-div">
        <a href="javascript:;" class="file">选择文件
          <input type="file" ref="upload" name="" id="" @change="changeFile"/>
        </a>
      </div>
    </div>
  </div>
  <div class="top-row" v-if="convertStatus===1">
    <div class="top-row">
      <img class="img-item loading-img" src="../../assets/img/loading.gif"/>
    </div>
    <div class="bold-tips check-tips">
     <span class="check-text">正在转换文件，请稍后...</span>
    </div>
  </div>

  <div v-if="convertStatus===2">
    <div class="top-row">
      <img class="img-item" src="../../assets/img/file-blue.png"/>
    </div>
    <div class="bold-tips check-tips">
      <span><img class="check-img" src="../../assets/img/check.png"/></span><span class="check-text">转换成功</span></div>
    <div class="upload-div">
      <a href="javascript:;" class="file empty-btn">继续上传
        <input type="file" ref="keepUpload" name="" @change="changeFile">
      </a>
      <a href="javascript:;" class="file" @click="download">下载结果
      </a>
    </div>
  </div>

  <div class="top-row" v-if="convertStatus===3">
    <div class="top-row">
      <img class="img-item" src="../../assets/img/file-red.png"/>
    </div>
    <div class="bold-tips check-tips">
      <span><img class="check-img" src="../../assets/img/error.png"/></span><span class="check-text">转换失败</span></div>
    <div class="upload-div">
      <a href="javascript:;" class="file empty-btn">重新上传
        <input type="file" ref="reUpload" name="" @change="changeFile">
      </a>
    </div>
  </div>

  <footer2 style="margin-top: 130px"></footer2>

</div>
</template>

<script>
import headerVue from "../../components/header";
import footer2 from "../../components/footer.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

export default {
  name: "detail",
  components:{
    headerVue,
    footer2
  },
  data(){
    return{
      barVisible: false,
      convertParam: null,
      fileType: 'dbc',
      allowFileType: 'DBC',
      targetFileType: 'dbc',
      convertStatus: 0, // 0-init 1-正在转换 2-转换成功 3-转换失败
      targetUrl: null,
      errorFileTypeFlag: false
    }
  },
  methods:{
    toggleBar(barVisible){
      this.barVisible = barVisible
    },
    changeFile(e){
      console.log(e)
      this.errorFileTypeFlag = false
      let file = e.target.files[0]
      console.log('file', file)
      console.log('fileName', file.name, this.fileType)
      if (!file.name.endsWith('.'+this.fileType)){
        this.errorFileTypeFlag = true
        this.clearFiles()
        return false
      }
      this.convertStatus = 1
      let formData = new FormData();
      formData.append('file', file);
      formData.append('convertType', this.fileType);
      formData.append('targetType', this.targetFileType);

      //
      axios.post('/api/cdot/convert', formData).then(response =>{
        console.log(response)
        if (response.data.code === 200){
          this.convertStatus = 2
          this.targetUrl = response.data.msg
        }else{
          this.convertStatus = 3
        }
        this.clearFiles()
      })
    },
    clearFiles(){
      let upload = this.$refs.upload
      if (upload){
        upload.value = ''
      }
      let keepUpload = this.$refs.keepUpload
      if (keepUpload){
        keepUpload.value = ''
      }
      let reUpload = this.$refs.reUpload
      if (reUpload){
        reUpload.value = ''
      }
    },
    download(){
      // window.open(this.targetUrl)

      axios.get(this.targetUrl, { responseType: 'blob' })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '转换结果.'+this.targetFileType);
            document.body.appendChild(link);
            link.click();
          })
          .catch(error => {
            console.error(error);
          });
    }
  },
  created(){
    console.log(this.$route)
    this.convertParam = this.$route.params.param
    if (this.convertParam === 'dbcToExcel'){
      this.fileType = 'dbc'
      this.allowFileType = 'DBC'
      this.targetFileType = 'xlsx'
    }else if (this.convertParam === 'dbcToArxml'){
      this.fileType = 'dbc'
      this.allowFileType = 'DBC'
      this.targetFileType = 'arxml'
    } else if (this.convertParam === 'excelToDbc'){
      this.fileType = 'xlsx'
      this.allowFileType = 'XLSX'
      this.targetFileType = 'dbc'
    }else if (this.convertParam === 'excelToArxml'){
      this.fileType = 'xlsx'
      this.allowFileType = 'XLSX'
      this.targetFileType = 'arxml'
    }else if (this.convertParam === 'arxmlToDbc'){
      this.fileType = 'arxml'
      this.allowFileType = 'ARXML'
      this.targetFileType = 'dbc'
    }else if (this.convertParam === 'arxmlToExcel'){
      this.fileType = 'arxml'
      this.allowFileType = 'ARXML'
      this.targetFileType = 'xlsx'
    }
  }
}
</script>

<style scoped>
.top-row{
  margin-top: 180px;
  text-align: center;
}

.img-item{
  width: 70px;
}

.loading-img{
  width: 280px;
}

.mid-symbol{
  width: 30px;
  margin-bottom: 20px;
}

.bold-tips{
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
}

.grey-tips{
  font-size: 12px;
  text-align: center;
  color: #858c99;
}

.upload-div{
  text-align: center;
  margin-top: 30px;
}

.file {
  position: relative;
  display: inline-block;
  background: #1a66ff;
  border: 1px solid #1a66ff;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #FFFFFF;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  font-size: 14px;
}
.file input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}
.file:hover {
  background: #4287ff;
  border-color: #4287ff;
  color: #FFFFFF;
  text-decoration: none;
}

.empty-btn{
  border: 1px solid #dcdfe5;
  background-color: #FFFFFF;
  color: #030a1a;
  margin-right: 10px;
}

.empty-btn:hover{
  background-color: #FFFFFF;
  border: 1px solid #4287ff;
  color: #4287ff;
}

.check-img{
  width: 23px;
  margin-top: 20px
}

.check-text{
  margin-top: 21px;
  margin-left: 8px;
}

.check-tips{
  display: flex;
  justify-content: center;
  width: 250px;
  margin: 0 auto;
}
</style>
