<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
    <!-- 导航栏 -->
    <header-vue @toggleBar="toggleBar"></header-vue>
    <div class="bg-title" :style="{'margin-top': barVisible ? '60px' : 0}">
      <div class="text-title">企业新闻</div>
      <div class="text-content">更多最新精彩资讯，感受企业魅力</div>
    </div>
		<!-- 面包屑 -->
		<div class="Crumbs mobile-crumbs">
			<div>当前位置：首页><span>新闻</span></div>
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>{{ newsInfo.title }}</h1>
					<div class="NewsDetails_cont_title_span">
						<img src="../assets/img/newsDetail/矩形.png" alt="" /><span
							>发布时间：{{ newsInfo.createTime }}&ensp;&ensp;
						</span>
						<img src="../assets/img/newsDetail/矩形(1).png" alt="" /><span
							>浏览次数：{{ newsInfo.readCount }}
						</span>
					</div>
				</div>
				<div class="NewsDetails_cont_text" v-html="newsInfo.content">
				</div>

			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import headerVue from "../components/header";
export default {
	name: "keji",
	components: {
		footer2,
    headerVue,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../assets/img/keji/矿山banner1.png"),
			// 这是我在父的组件里 定义的变量 表示当前是数组里第1个页面
			artcleID: 0,
      barVisible: false,
			p1: "更多最新精彩资讯，感受企业魅力",
      newsList: [
        {title: 'AutoSAR CP解决方案', createTime: '2023-08-05', readCount: 105,
          content: '<div style="margin-top: 40px">提供服务：AutoSAR CP基础软件配置、集成、测试验证解决方案</div>' +
              '<p>随着汽车电子系统的复杂性和集成度日益提高，AutoSAR CP（Classic Platform）作为基础软件架构，已经成为汽车电子系统开发的重要基石。为了满足汽车行业对于高效、可靠且符合标准的软件解决方案的需求，我们推出了AutoSAR CP基础软件配置、集成、测试验证的全方位服务。</p>' +
              '<div>一、AutoSAR CP基础软件配置</div>' +
              '<p>在AutoSAR CP基础软件配置环节，我们采用ETAS工具链，特别是ISOLAR A/B等专业工具，为用户提供灵活且符合标准的配置方案。这些工具支持从dbc文件直接导入通信矩阵，将CAN、LIN等通信协议的文件解析成AutoSAR系统描述文件。同时，用户也可以手动新建信号文件，在PDU和Frame中指定相关信息，以满足特定的系统需求。\n</p>' +
              '<div>二、集成服务</div>' +
              '<p>在基础软件配置完成后，我们的集成服务将确保各个软件组件能够无缝地协同工作。我们拥有丰富的经验和专业的技术团队，能够处理复杂的集成问题，确保系统的稳定性和可靠性。</p>' +
              '<div>三、测试验证</div>' +
              '<p>为了确保AutoSAR CP基础软件的正确性和可靠性，我们提供全面的测试验证服务。我们的测试验证流程涵盖了单元测试、集成测试、系统测试等多个阶段，确保在各个层面上都达到预期的性能和质量要求。\n</p>' +
              '<div>四、ETAS工具链的优势</div>' +
              '<p>作为我们实际使用的基础软件配置工具，ETAS工具链具有许多优势。首先，它提供了直观的用户界面和强大的功能，使得配置过程更加高效和准确。其次，ETAS工具支持多种数据格式和通信协议，具有高度的灵活性和可扩展性。最后，ETAS作为行业领先的汽车电子系统开发和测试解决方案提供商，其工具链得到了广泛的认可和应用。\n</p>' +
              '<div>五、总结</div>' +
              '<p>我们的AutoSAR CP基础软件配置、集成、测试验证解决方案结合了ETAS工具链的优势，为用户提供了一套高效、可靠且符合标准的汽车电子系统开发方案。我们致力于为客户提供最优质的服务，助力汽车行业的创新与发展。\n</p>'
        },
        {title: '嵌入式Tessy测试服务', createTime: '2023-11-22', readCount: 263,
          content: '<div style="margin-top: 40px">提供服务：嵌入式Tessy测试服务</div>' +
              '<p>我们提供专业的嵌入式Tessy测试服务，旨在确保您的嵌入式软件系统达到最高的质量标准。Tessy是一种功能强大的自动化测试工具，专为嵌入式软件开发而设计，能够有效地验证和验证嵌入式系统的功能和性能。\n</p>' +
              '<div>一、我们的服务包括</div>' +
              '<p>测试策略制定： 根据您的项目需求和软件规范，制定最佳的测试策略，确保全面覆盖功能和性能测试。</p>' +
              '<p>测试用例开发： 基于系统规格和功能要求，开发详细的测试用例，以确保每个功能模块的正确性和稳定性。</p>' +
              '<p>自动化测试执行： 利用Tessy的自动化测试功能，高效执行大规模的测试套件，提高测试效率和一致性。</p>' +
              '<p>测试结果分析与报告： 分析测试结果，生成详尽的测试报告，包括问题追踪和建议的改进措施，帮助您快速定位和解决潜在问题。\n</p>' +
              '<div>二、为什么选择我们的Tessy测试服务？\n</div>' +
              '<p>专业经验： 我们拥有丰富的嵌入式系统测试经验，能够针对不同行业和应用场景提供定制化的解决方案。\n</p>' +
              '<p>高效性能： Tessy测试工具的使用使得测试执行更加高效和可靠，确保软件系统的质量和稳定性。</p>' +
              '<p>全面支持： 我们提供从测试策略制定到结果分析的全方位支持，帮助您在整个开发周期中控制和管理测试过程。</p>' +
              '<p>定制服务：我们根据您的具体需求提供定制化的测试服务，确保满足您的个性化需求。\n</p>' +
              '<p>通过我们的嵌入式Tessy测试服务，您可以放心地推出高质量的嵌入式软件产品，满足市场需求并提升用户体验。无论您的项目规模如何，我们都致力于为您提供卓越的测试解决方案。</p>'
        },
        {title: '车载网络测试外包服务', createTime: '2024-05-02', readCount: 189, content:
              '<div style="margin-top: 40px">提供服务：车载网络测试外包服务</div>' +
              '<p>我们提供全面的车载网络测试外包服务，通信网络包括车载以太网、CAN、LIN，涵盖刷写、诊断、OTA、网关、HIL、Tessy、BCM、TBOX、SOME/IP多个关键领域。作为汽车行业的测试专家，我们致力于帮助客户确保其车载系统的稳定性、安全性和性能优化，以应对快速发展和日益复杂的车载技术挑战。\n</p>' +
              '<div>一、我们的服务包括</div>' +
              '<p>车载网络协议测试： 使用Vector的测试工具和技术，对车载以太网、CAN和LIN等协议进行全面的功能验证和互操作性测试。</p>' +
              '<p>刷写和OTA测试： 确保车载电子控制单元（ECU）的固件刷写和远程更新（OTA）过程安全可靠，遵循最佳实践和行业标准。\n</p>' +
              '<p>诊断系统验证： 测试和验证车辆诊断系统的功能性和效率，确保能够准确快速地检测和解决故障。\n</p>' +
              '<p>网关和通讯测试： 验证车载网关的性能和稳定性，确保各个子系统之间的有效通信和数据交换。</p>' +
              '<p>硬件在环测试（HIL）： 使用HIL仿真平台进行实时验证，加速开发周期，降低开发成本和风险。\n</p>' +
              '<p>Tessy测试服务： 利用Tessy工具进行嵌入式软件单元测试，确保代码质量和功能完整性。</p>' +
              '<p>BCM和TBOX测试： 对车身控制模块（BCM）和远程车辆通信（TBOX）等关键组件进行全面测试和验证。\n</p>' +
              '<div>二、我们的外包服务经验包括</div>' +
              '<p>车载网络测试：红旗C095、解放J7、红旗C100、柴油国六自动化平台。</p>' +
              '<p>HIL功能测试：解放新能源整车控制器。</p>' +
              '<p>Tessy测试：恒润EAS470。\n</p>' +
              '<p>BCM测试：红旗C100、红旗C001、红旗C206、红旗C229、红旗C255、红旗C095、红旗C206 phev、红旗C100 phev、 红旗E115 国内版、红旗E111海外版、红旗N897、中华汽车、红旗V506。\n</p>' +
              '<p>T-BOX测试：红旗C255、奔腾D501、奔腾D501 phev、奔腾NAT。</p>' +
              '<p>通过我们专业的车载网络测试外包服务，您可以获得最佳的测试解决方案，确保您的汽车电子系统在市场上表现卓越，提升竞争力并满足用户的高要求。我们致力于提供高质量、高效率的测试支持，帮助您实现项目目标和商业成功。</p>' +
              '<p>联系我们，了解更多如何优化您的车载系统测试和验证流程！\n</p>'
        }
      ],
      newsInfo: {}
		};
	},

	methods: {
    toggleBar(barVisible){
      this.barVisible = barVisible
    }
  },
  created() {
	  let index = this.$route.query.index || 0
    if (index >= this.newsList.length){
      index = this.newsList.length - 1
    }
	  this.newsInfo = this.newsList[index]
  }
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
.bg-title{
  background: url("../assets/img/keji/矿山banner1.png") no-repeat;
  background-size: auto 100%;
  width: 100vw;
  height: 300px;
  text-align: center;
  /*line-height: 300px;*/
  color: #FFFFFF;
}

.text-title{
  padding-top: 105px;
  font-size: 40px;
}

.text-content{
  margin-top: 20px;
}

/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}

.NewsDetails_cont_title > h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}
.NewsDetails_cont_title img {
	vertical-align: middle;
}
.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}
.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}

.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}

.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}
.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}
.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}

@media only screen and (min-width: 800px) {
  /* 新闻详情 */
  .NewsDetails_cont {
    width: 1200px;
    /*width: 100vw;*/
    margin: 0 auto;
  }

  .NewsDetails_cont_text >>> p {
    line-height: 30px;
    width: 1000px;
    margin: 40px 100px;
  }
  .NewsDetails_cont_text >>> div {
    margin-left: 90px;
    font-size: 24px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 800px){
  .mobile-crumbs{
    width: 100vw;
    padding-left: 20px;
  }

  /* 新闻详情 */
  .NewsDetails_cont {
    /*width: 1200px;*/
    width: 100vw;
    margin: 0 auto;
  }

  .NewsDetails_cont_text >>> p {
    line-height: 30px;
    /*width: 1000px;*/
    margin: 40px 20px;
  }

  .NewsDetails_cont_text >>> div {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
